import React,{ useState, useEffect } from "react";
import {Container, Row,Col,FormGroup,Input,Button,Label,Form } from "reactstrap";
import {ErrorMessage, useForm } from 'react-hook-form';

import { useAlert } from 'react-alert';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useLocation,useHistory,Redirect,Link,useParams } from "react-router-dom";
const EquipmentPage = (props) => { 
   const alert = useAlert();
   let history = useHistory();
   const [visible, setVisibale] = useState(false);
   const location = useLocation();
   const { register, handleSubmit,  errors , watch } = useForm({ mode: "onSubmit"});

   const onSubmit = async (data,e) => {
      setVisibale(true);
      const params = new URLSearchParams(location.search);
      var usertoken=props.match.params.token;
      let postJson = { name: data.name, email:data.email, message:data.message};
      let path = apiUrl.contactEnquiry;
      const fr = await Helper.post(postJson, path);
      const response_data = await fr.response.json();
      
      if (fr.status === 200) {
        if (response_data.success) {
          alert.success(response_data.msg);
          setVisibale(false);
          e.target.reset();
          //props.history.push('/login');
        } else {
          alert.error(response_data.msg);
          setVisibale(false);
        }
      } else {
        alert.error(response_data.error);
        setVisibale(false);
      }
    };
  

  return (
    <>
      <section id="faq" class="faq space_top">
               <Container>
                  <div className="contact_heading text-center">
                    <h2>Equipment</h2>
                  </div>
                              </Container>
            </section>
        
      </>
    );  
}
export default EquipmentPage;
