import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Input, Button, Label, Form } from "reactstrap";
import { ErrorMessage, useForm } from 'react-hook-form';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useLocation, useHistory, Redirect, Link, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';


const ResetPassword = (props) => {
  const session = useSession();
  const alert = useAlert();
  const { register, handleSubmit, errors, watch } = useForm({ mode: "onSubmit" });
  const [token] = useState(session.token);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [visible, setVisibale] = useState(false);
  const location = useLocation();


  const onSubmit = async (data) => {
    setVisibale(true);
    const params = new URLSearchParams(location.search);
    var usertoken = props.match.params.token;
    let postJson = { password: data.password, user_type: 'user', token: usertoken };
    let path = apiUrl.resetPassword;
    const fr = await Helper.post(postJson, path);
    const response_data = await fr.response.json();

    if (fr.status === 200) {
      if (response_data.success) {
        alert.success(response_data.msg);
        setVisibale(false);
        props.history.push('/login');
      } else {
        alert.error(response_data.msg);
        setVisibale(false);
      }
    } else {
      alert.error(response_data.error);
      setVisibale(false);
    }
  };


  return (
    <>
      <section
        className="login_signup_sec py-4 py-md-5"
      >
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col xs={12} md={7} lg={5} xl={6}>
              <div className="login_signup_block p-4">
                <div className="form_logo text-center mb-5">
                  <img src="../assets/img/logo.png" alt="Trading Oilfield" />
                </div>

                <Form className="login_signup_form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <label>New Password</label>
                    <input type="password" name="password" className="form-control" placeholder=""
                      ref={register({
                        required: 'Please enter password ', minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters"
                        }
                      })} />
                    <p className="text-danger marginmessage">
                      <ErrorMessage errors={errors} name="password">
                        {({ message }) => <p className={"text-danger"}>{message}</p>}
                      </ErrorMessage></p>
                  </FormGroup>
                  <FormGroup>
                    <label>
                      Confirm Password
                    </label>
                    <input type="password" name="c_password" className="form-control" placeholder="" ref={register({ validate: (value) => value === watch('password') || "Passwords don't match" })} />
                    <p className="text-danger marginmessage">
                      <ErrorMessage errors={errors} name="c_password" className="text-danger marginmessage">
                        {({ message }) => <p className="text-danger marginmessage">{message}</p>}
                      </ErrorMessage></p>
                  </FormGroup>
                  <input type="submit" className="form-control btn" value="Submit" />

                  <div className="dont_signup_text text-center py-5">
                    <p>
                      Want to go back? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </Form>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ResetPassword;
