import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, FormGroup, Input, Form, Label, } from "reactstrap";
import { useForm } from 'react-hook-form';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useLocation, useHistory } from "react-router-dom";
import { Button, Card, Accordion } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import Status from './Action/Status';
import Pagination from "react-js-pagination";
import ListSidebar from "./sidebar";
import queryString from 'query-string';
const queryString1 = require("query-string");
const Listing = (props) => {
  const { title, actions, wishCount } = props;
  const session = useSession();
  const location = useLocation();
  const { register, handleSubmit, formState: { errors }, watch } = useForm({ mode: "onSubmit" });
  const [products, setProduct] = useState([]);
  const [totalitems, setTotalItems] = useState('');
  const [token] = useState(session.token);

  const [isserach, setIsserach] = useState(false);
  const [visible, setVisibale] = useState(false);
  const [imgurl, setImageUrl] = useState('');
  const [keywords, setKeyWords] = useState('');
  const [type, setType] = useState('');
  const [selectCategory, setCategory] = useState([]);
  const [selectSubCategory, setSubCategory] = useState([]);
  const [activepage, setActivePage] = useState(1);
  const [query, setQuery] = useState({});
  const [isRentTypeCheck, setIsRentTypeCheck] = useState(false);
  const [isSellTypeCheck, setIsSellTypeCheck] = useState(false);
  const [isNewCheck, setNewCheck] = useState(false);
  const [isMiddleCheck, setMiddleCheck] = useState(false);
  const [isOldCheck, setOldCheck] = useState(false);
  const [typevalue, setTypeValueQuery] = useState(false);
  const MAX_LENGTH = 70;
  const alert = useAlert();

  let history = useHistory();

  const getCategory = async () => {
    let path = apiUrl.get_category_value;
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {

        setCategory(res.results.docs || []);

      } else {
        alert.error(res.msg);

      }
    } else {
      alert.error(res.error);
    }
  }
  const setSortBy = async (value, page = activepage) => {
    setVisibale(true);
    const itemsPerPage = 12;
    let path;
    setActivePage(page)
    query["page"] = page
    query["itemsPerPage"] = itemsPerPage
    query["sortby"] = value
    let queryString = Helper.serialize(query);
    path = apiUrl.product_listing + `?${queryString}`;
    getData(path)
  };
  const pageData = async (page = activepage) => {

    setVisibale(true);
    const itemsPerPage = 12;
    let path;
    setActivePage(page)
    query["page"] = page
    query["itemsPerPage"] = itemsPerPage
    let queryString = Helper.serialize(query);
    path = apiUrl.product_listing + `?${queryString}`;
    getData(path)
  };
  const pageData1 = async (page) => {
    setVisibale(true);
    const itemsPerPage = 12;
    let path;
    setActivePage(page)
    query["page"] = page
    query["itemsPerPage"] = itemsPerPage
    let queryString = Helper.serialize(query);
    path = apiUrl.product_listing + `?${queryString}`;
    getData(path)
  };
  const resetSearch = async (page = activepage) => {
    const itemsPerPage = 12;
    let path = apiUrl.product_listing + '?page=' + `${page}` + '&itemsPerPage=' + `${itemsPerPage}`;
    getData(path)
  }
  let subcat_id = "";


  const handleSearching = async (page = activepage) => {
    const params = new URLSearchParams(location.search);
    setIsserach(true);
    params.set("keyword", keywords);
    history.push({
      pathname: "/listing",
      search: "?" + params,
    });
    query["keyword"] = keywords

    const itemsPerPage = 12;
    const subcatid = subcat_id;

    let path;
    let sort;
    //let page = 1;
    setActivePage(page)

    let queries = {
      page: page,
      itemsPerPage: itemsPerPage,
      keyword: keywords,
      subcatid: subcatid,
    }
    setQuery(queries)
    path = apiUrl.product_listing + '?page=' + `${page}` + '&itemsPerPage=' + `${itemsPerPage}` + '&keyword=' + `${keywords}` + '&sub_category_id=' + `${subcatid}`;
    getData(path);
  };
  let catId = '';
  let parent_catId = '';
  let type_param = '';
  let type_condition = '';
  const handleClickCategory = (category, page = activepage) => {
    const params = new URLSearchParams(location.search);

    if (category.value != undefined) {

      let queryStringParsed = queryString1.parse(location.search);
      let type = "type";
      let id = category.key;
      if (Object.keys(queryStringParsed).length === 0) {

        history.push(`/listing?${type}=${id}`);
        query["product_type"] = id

        params.set("type", `?${type}=${id}`)

      } else {

        if (
          queryStringParsed[type] &&
          !queryStringParsed[type].includes(id.toString())
        ) {
          queryStringParsed[type] = queryStringParsed[type] + ',' + id;
          history.push(`/listing${location.search}&${type}=${id}`);
          type_param = queryStringParsed[type];
          query["product_type"] = queryStringParsed[type]
          params.set("type", `${location.search},${id}`)

        } else {

          if (queryStringParsed[type]) {
            if (typeof queryStringParsed[type] === "string") {

              delete queryStringParsed[type];
            }
            else {
              var index = queryStringParsed[type].indexOf(id.toString());
              queryStringParsed[type].splice(index, 1);
            }
            history.push(
              `/listing?${queryString1.stringify(queryStringParsed)}`
            );
            query["product_type"] = queryStringParsed[type]
            type_param = queryStringParsed[type];
            params.set("type", `${queryString1.stringify(queryStringParsed)}`)

          } else {

            history.push(`/listing${location.search}&${type}=${id}`);
            query["product_type"] = id

            type_param = id;
            params.set("type", `${location.search}&${type}=${id}`)

          }
        }
      }
    } else if (category.cvalue != undefined) {

      let queryStringParsed = queryString1.parse(location.search);
      let condition = "condition";
      let id = category.ckey;
      if (Object.keys(queryStringParsed).length === 0) {
        history.push(`/listing?${condition}=${id}`);
        query["condition"] = id
        params.set("condition", `?${condition}=${id}`)
      }
      else {
        if (
          queryStringParsed[condition] &&
          !queryStringParsed[condition].includes(id.toString())
        ) {
          queryStringParsed[condition] = queryStringParsed[condition] + ',' + id;
          history.push(`/listing${location.search}&${condition}=${id}`);
          type_condition = queryStringParsed[condition];
          query["condition"] = queryStringParsed[condition];
          params.set("condition", `${location.search},${id}`);
        } else {

          if (queryStringParsed[condition]) {
            if (typeof queryStringParsed[condition] === "string")
              delete queryStringParsed[condition];
            else {
              var index = queryStringParsed[condition].indexOf(id.toString());
              queryStringParsed[condition].splice(index, 1);
            }
            history.push(
              `/listing?${queryString1.stringify(queryStringParsed)}`
            );
            type_condition = queryStringParsed[condition];
            query["condition"] = queryStringParsed[condition];
            params.set("condition", `${queryString1.stringify(queryStringParsed)}`);
          } else {

            history.push(`/listing${location.search}&${condition}=${id}`);
            type_condition = id;
            query["condition"] = id;
            params.set("condition", `${location.search}&${condition}=${id}`);
          }
        }
      }
    } else {

      params.set("parent_id", category.parent_id);
      params.set("categoryId", category.id);
      history.push({
        pathname: "/listing",
        search: "?" + params,
      });
      query["categoryId"] = params.get("categoryId")
      query["parent_id"] = params.get("parent_id")
      catId = params.get("categoryId");
      parent_catId = params.get("parent_id");
    }

    setVisibale(true);
    const itemsPerPage = 12;
    let path;
    setActivePage(page)
    query["page"] = page
    query["itemsPerPage"] = itemsPerPage

    let queryString = Helper.serialize(query);
    path = apiUrl.product_listing + `?${queryString}`;
    getData(path)
  };

  const getData = async (path, page = activepage) => {
    //let path = apiUrl.product_listing + '?page=' + page + '&itemsPerPage=10';
    let queryStringParsed = queryString1.parse(location.search);


    const itemsPerPage = 12;
    const params = new URLSearchParams(location.search);


    if (params.get("categoryId") && catId == "") {
      if (catId != "") {
        query["categoryId"] = catId
      } else {
        query["categoryId"] = params.get("categoryId")
      }

      if (parent_catId != "") {
        query["parent_id"] = parent_catId
      } else {
        query["parent_id"] = params.get("parent_id")
      }
      let queryString = Helper.serialize(query);
      path = apiUrl.product_listing + `?${queryString}`;
      //console.log("jhkjh"+ query["categoryId"])
    }
    if (params.get("keyword")) {
      query["keyword"] = params.get("keyword")
      let queryString = Helper.serialize(query);
      path = apiUrl.product_listing + `?${queryString}`;
    }
    if (params.get("type") && type_param == "") {
      let queryStringParsed = queryString1.parse(location.search);
      query["product_type"] = queryStringParsed['type']
      let queryString = Helper.serialize(query);
      path = apiUrl.product_listing + `?${queryString}`;

    }
    if (params.get("condition") && type_condition == "") {
      let queryStringParsed = queryString1.parse(location.search);
      query["condition"] = queryStringParsed['condition']
      let queryString = Helper.serialize(query);
      path = apiUrl.product_listing + `?${queryString}`;

    }

    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {

        setProduct(res.results.docs || []);
        setTotalItems(res.results.totalDocs);
        setIsserach(false);
        setVisibale(false);
        setImageUrl(res.imgurl);
      } else {
        //alert.error(res.msg);
        setIsserach(false); setVisibale(false);
      }
    } else {
      //alert.error(res.error);
      setIsserach(false); setVisibale(false);
    }
  }

  const onReset = (e) => {
    setQuery({})
    setIsRentTypeCheck(false);
    setKeyWords('');
    setIsSellTypeCheck(false);
    setNewCheck(false);
    setMiddleCheck(false);
    setOldCheck(false);
    resetSearch();
    setActivePage(1);

  };
  useEffect(() => {
    pageData();
    getCategory();

  }, []);
  function SidebarAddClass() {
    document.getElementById("left_sidebar").classList.toggle("sidebar_sec");
  }

   
  return (

    <div>

      <Container>

        <span onClick={SidebarAddClass} className="d-block mt-3 d-md-none">
          <button className="btn btn-filter"><i className="fas fa-bars"></i> Filter</button>
        </span>

        <div className="Search_sec my-3 mt-md-5 mb-md-4">
          <Row>
            <Col md={8}>

              <div className="Search_field">
                <input type="text" value={keywords} onChange={(e) => { setKeyWords(e.target.value) }} name="" className="form-control" placeholder="Search" />
                <button onClick={handleSearching} className="btn_search btn"><i class="fas fa-search"></i></button>
              </div>

            </Col>


            <Col md={4}>

              <div className="d-flex pt-2 pt-md-0">
                <select className="form-control custom-select" onChange={(e) => { setSortBy(e.target.value) }}>
                  <option value="">Sort by</option>
                  <option value="a">A to Z</option>
                  <option value="z">Z to A</option>
                </select>

                <a href="#" className="gird ml-2"><i class="fas fa-bars"></i></a>
              </div>

            </Col>



          </Row>

        </div>


        <Row>

          <Col md={3}>
            <ListSidebar
              onClick={handleClickCategory}
            />
          </Col>



          <Col md={9}>

            <div className="list_wrapper">
              {(() => {
                if (products.length == 0) {
                  return (
                    <label className="noRecord">No Record Found !</label>
                  )
                }
              })()}
              {products.map((item, key) => {

                return (

                  <div className="box_list">

                    <div className="img listing-img">
                      <img src={`${imgurl}${item.primary_image}`} />
                    </div>
                    <div className="p-3">
                      <span className="d-block name">{item.product_name}</span>
                      <ul className="contact d-flex py-1">
                        <li><i class="fas fa-tags pr-1"></i> {item.product_id}</li>

                        <li><i class="fas fa-map-marker-alt pr-1"></i> {item['location_id'] != "" ? item['location_id']?.location_name : '' }</li>
                        
                      </ul>

                      {(() => {
                        if (item.show_on_front == 1 && item.price != null) {
                          return (
                            <span className="d-block price">Price: ${item.price}</span>
                          )
                        }
                      })()}
                      {item.description.length > MAX_LENGTH ?
                        (
                          <p className="m-0 py-1 descraption">
                            {`${item.description.substring(0, MAX_LENGTH)}...`}
                          </p>
                        ) :
                        <p className="m-0 py-1 descraption">{item.description}</p>
                      }

                      <div className="btn_box"><a href={`/detail-product/${item.id}`} className="view_link mt-2 d-block">View</a></div>
                    </div>

                    
                    {(() => {
                      if (item.marksold == 0) {
                        return (
                          <div className="rent_tag">{item.product_type}</div>
                        )
                      }else{

                      }
                    })()}
                    
                    { /* {item?.product_type === 'Both' ? "Both (Rent/Sale)" : item.product_type} */ }

                    { /* {item?.marksold !== 0 ? " " : item.product_type} */ }
                          
                  
                     
                    {console.log('item type',item.product_type)}
                    {(() => {
                      if (item.marksold == 1) {
                        return (
                          <div className="sold_tag">Sold</div>
                        )
                      }
                    })()}


                  </div>
                )
              })}



            </div>








          </Col>

        </Row>
      </Container>
      <div className="pagination_deshbaord d-flex justify-content-center justify-content-md-end">
        {products !== 0 && <div className="show-pagination technician-page">
          <Pagination
            activeClass={""}
            activeLinkClass={"page-link active"}
            itemClass={"page-item"}
            linkClass={"page-link"}
            activePage={activepage}
            itemsCountPerPage={10}
            totalItemsCount={totalitems}
            pageRangeDisplayed={4}
            prevPageText="Previous"
            nextPageText="Next"
            firstPageText="<"
            lastPageText=">"
            onChange={pageData1}
          />
        </div>}


      </div>


    </div>
  );
}
export default Listing;
