import React, { useState, useEffect } from "react";
import {Col, Container, Row,Form,FormGroup } from "reactstrap";

import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory,useLocation } from "react-router-dom";
import { ErrorMessage,useForm } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { Button, Card, Accordion } from 'react-bootstrap';
import ProfileHeader from "./Action/ProfileHeader";
import ProfileSidebar from "./Action/ProfileSidebar";
const Changepassword = (props) => { 
  const session = useSession();
  const [token] = useState(session.token);
  const location = useLocation();
  const alert = useAlert();
  const [profileImage, setProfileImage] = useState('');
  const [UserData, setData] = useState({});
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const { register,handleSubmit,setValue, errors ,watch } = useForm();
    function SidebarAddClass() {
      document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
    }
    const checkAuthToken = localStorage.getItem("usertoken");
    let history = useHistory();
    if (checkAuthToken==null) {
      history.push("/login");
    }
    const user_Id = localStorage.getItem('userid');
    const getData = async () => {
      
      let path = apiUrl.get_user + '/' + `${user_Id}`;
      const fr = await Helper.get(path, token);
      const res = await fr.response.json();
      if (fr.status === 200) {
          if (res.success) {
              setId(res.results.id);
              
              setData(res.results);
              console.log(res.results.image)
              let imgurl=res.results.image
              setProfileImage(imgurl)
          } else {
              console.log(res.msg);
          }
      } else {
          console.log(res.msg);
      }
  
  };
  const [visible, setVisibale] = useState(false);
  const onSubmit = async data => {
 
    setVisibale(true);
    const params = new URLSearchParams(location.search);
    var usertoken=props.match.params.token;
    let postJson = { oldPassword:data.current_password,newPassword: data.password, user_type: 'user',userId:user_Id };
    let path = apiUrl.changePassword;
    const fr = await Helper.post(postJson, path);
    const response_data = await fr.response.json();
    
    if (fr.status === 200) {
      if (response_data.success) {
        alert.success(response_data.msg);
        setVisibale(false);
        props.history.push('/login');
      } else {
        alert.error(response_data.msg);
        setVisibale(false);
      }
    } else {
      alert.error(response_data.error);
      setVisibale(false);
    }
  };
  useEffect(() => {
    getData()
    if (UserData) {
      setData(UserData)
    }
  }, []);
   
    function SidebarRemoveClass(){
      console.log("tset")
       document.getElementById("OnscrollBody").classList.remove("show_sidebar");
    }

 
    return (
      
      <div>  
 
 <ProfileHeader item={UserData}/>
      <div className="dashboard_outer">
      <ProfileSidebar item={UserData}/>
{/* dashbaord-inner */}

<div className="dashboard_inner">
<div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                    <a href="#" onClick={SidebarAddClass} className="toggle_btn d-lg-none"><i className="fas fa-bars"></i></a>
</div>
<div className="dash_main_inner">

<div className="">
<div className="row align-items-center">
              <div className="col-sm-5">
              <h2 className="dash_heading pl-3 pl-md-0">Change Password</h2>
              </div>
              </div>



<div className="change_password">
<form className="my_profile_form" onSubmit={handleSubmit(onSubmit)}>
<input type="password" className="form-control" name="current_password" placeholder="Old Password" 
ref={register({ required: 'Please enter old password ',minLength: {
        value: 8,
        message: "Password must have at least 8 characters"
      } })}/>
  <p className="text-danger marginmessage">
<ErrorMessage errors={errors} name="current_password">
    {({ message }) => <p className={"text-danger"}>{message}</p>}
  </ErrorMessage></p>
<input type="password" className="form-control" name="password" placeholder="New Password" 
ref={register({ required: 'Please enter new password ',minLength: {
          value: 8,
          message: "Password must have at least 8 characters"
        } })}/>
    <p className="text-danger marginmessage">
  <ErrorMessage errors={errors} name="password">
      {({ message }) => <p className={"text-danger"}>{message}</p>}
    </ErrorMessage></p>
<input type="password" className="form-control" name="c_password" placeholder="Confirm New Password" 
ref={register({ validate: (value) => value === watch('password') || "Passwords don't match" })}/>
  <p className="text-danger marginmessage">
  <ErrorMessage errors={errors} name="c_password" className="text-danger marginmessage">
      {({ message }) => <p className="text-danger marginmessage">{message}</p>}
    </ErrorMessage></p>

<div className="ml-auto text-right cancel_button mt-3">

  <ul>
    <li>
    <a href="#">Cancel</a>
    </li>
    <li>
    <Button type="Submit" value="Save Section">Change Password</Button>
    </li>
  </ul>

</div>
</form>
</div>

</div>

</div>

</div>
{/* dashbaord-inner */}





      </div> 


      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>


      </div>
    );
  }
  
  export default Changepassword;
