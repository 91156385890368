import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Input, Label, } from "reactstrap";
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory, Redirect } from "react-router-dom";
import { Button, Nav, Tabs, Modal, Tab, Form } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage, useForm } from 'react-hook-form';
import Enquiry from "./enquiry";
import { useAlert } from 'react-alert';


const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const ProductDetails = (props) => {


  const alert = useAlert();
  const [show, setShow] = useState(false);
  const session = useSession();
  const [CategoryId, setCategoryId] = useState('');
  const [SubCatId, setSubCategoryId] = useState('');
  const [SetProductTypeValue, SetProductType] = useState('');
  const [token] = useState(session.token);
  const checkAuthToken = localStorage.getItem("usertoken");
  const [allImages, setAllImages] = useState([]);
  const [images, setImages] = useState([]);
  const [imgurl, setImageUrl] = useState('');
  const [editprimaryPreview, setPrimaryImage] = useState('');
  const [ProductData, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [values, setValues] = useState('');
  const { register, handleSubmit, errors, watch } = useForm({ mode: "onSubmit" });
  const [visible, setVisibale] = useState(false);

  console.log(ProductData)

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const buyClose = async () => {

    props.history.push({
      pathname: "/enquiry/" + ProductData._id,
      state: {
        type: 'Buy',
        units: ProductData.units
      }
    });
    setShow(false);
  }

  const handleClose = async (data) => {

    props.history.push({
      pathname: "/enquiry/" + ProductData._id,
      state: {
        units: data.units,
        start_date: data.start_date,
        end_date: data.end_date,
        type: 'Rent'
      }
    });
    setShow(false);
  }
  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [setdisable, setDisable] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Units, setUnit] = useState('');
  const changeUnit = async (unit) => {

    setUnit(unit)
  }
  const getProductData = async () => {

    let path = apiUrl.get_product + '/' + `${props.match.params.id}`;

    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {
        setId(res.results.id);
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>',res.results.locations[0].location_name);
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>', res.results.locations[0]);

        setCategoryId(res.results.category_id);
        setSubCategoryId(res.results.sub_category_id);
        SetProductType(res.results.product_type);
        setAllImages(res.allImages.docs || []);
        setPrimaryImage(res.results.primary_image)
        setImageUrl(res.imgurl);
        if (res.results.marksold == 1) {
          setDisable(true)
        } else {
          setDisable(false)
        }
        var primary = {};
        primary['original'] = res.imgurl + res.results.primary_image;
        primary['thumbnail'] = res.imgurl + res.results.primary_image;
        primary['showFullscreenButton'] = false;
        images.push(primary);
        for (var i = 0; i < res.allImages.docs.length; i++) {
          let arrayimages = {};
          arrayimages['original'] = res.imgurl + res.allImages.docs[i].product_image;
          arrayimages['thumbnail'] = res.imgurl + res.allImages.docs[i].product_image;

          images.push(arrayimages);
        }
        setData(res.results);
      } else {
        console.log(res.msg);
      }
    } else {
      console.log(res.msg);
    }
  };

  const facebook = "https://www.facebook.com/sharer/sharer.php?u=";
  const pintrest = "http://pinterest.com/pin/create/button/?url=";
  const twitter = "http://twitter.com/share?url=";
  const mail = "mailto:?subject=" + ProductData.product_name + "&amp;body="

  useEffect(() => {

    getProductData();

  }, []);

  const getCommantValues = (e) => {
    setValues(e.target.value)
  }

  const SubmitForm = (text) => async (formData) => {
    var user_id = localStorage.getItem('userid')
    setVisibale(true);
    let postJson = '';
    postJson = {
      user_type: 'user', product_id: id, user_id: user_id, enquiry_type: text, user_type: 'user', comment: values
    };

    let path = apiUrl.sendEnquiry;
    const fr = await Helper.post(postJson, path);
    const response_data = await fr.response.json();
    console.log('postJson->>>>>>>', response_data);
    if (fr.status === 200) {
      if (response_data.success) {
        alert.success(response_data.msg);
        setVisibale(false);
        setShow(false);
        props.history.push({
          pathname: "/detail-product/" + ProductData._id,
        });
      } else {
        alert.error(response_data.msg);
        setVisibale(false);
      }
    } else {
      setVisibale(false);
      alert.error(response_data.error);

    }
  }

  return (
    <>

      <section className="bread_sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="bread_sec_inner">
                <ul>
                  <li>
                    <a href={`/listing`}>
                      <span className="mr-2"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                      Back to Home
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {ProductData.product_name}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section className="order_detail_sec">
        <Container>
          <Row>

            <Col lg={7}>
              <div className="product_gallery">
                <ImageGallery items={images} />
              </div>
            </Col>
            <Col lg={5} className="pl-0">
              <div className="order_detail_inner order_det">
                <h2 className="mb-2">{ProductData.product_name}</h2>
                {(() => {
                  if (ProductData.marksold == '1') {
                    return (
                      <div className="sold_detail_tag">Sold</div>
                    )
                  }
                })()}

                <ul>
                  <li>

                    <span className="mr-2">
                      <img src="/assets/img/detail-icon1.png" alt="Product ID" className="img-fluid" />
                    </span>
                    {ProductData.product_id}
                  </li>
                  <li>

                    <span className="mr-2">
                      <img src="/assets/img/detail-icon2.png" alt="Manufacturer" className="img-fluid" />
                    </span>
                    {ProductData.manufacturer}
                  </li>
                  <li>

                    <span className="mr-2">
                      <img src="/assets/img/detail-icon3.png" alt="Year" className="img-fluid" />
                    </span>
                    {ProductData.year_built}

                  </li>
                  <li>

                    <span className="mr-2">
                      <img src="/assets/img/detail-icon4.png" alt="Condition" className="img-fluid" />
                    </span>
                    {(() => {
                      if (ProductData.condition != null) {
                        return (ProductData.condition == 'Old' ? 'Used' : ProductData.condition)
                      }
                    })()}
                  </li>
                </ul>

                <p className="n m-0">Location <i class="fas fa-map-marker-alt pr-1"></i>: {
                  ProductData?.locations && <>{ProductData.locations.length ? ProductData.locations?.[0].location_name : ""}</>
                }
                </p>
                <p className="n m-0">Number of Units: {ProductData.units} </p>
                <p className="n m-0">Last Inspection Date: {ProductData.last_inspection_date} </p>
                {(() => {
                  if (ProductData.show_on_front == 1 && ProductData.price != null) {
                    return (
                      <h2 className="price mt-2">Price: ${ProductData.price}</h2>
                    )
                  }
                })()}
                <hr></hr>
                <div className="desc">
                  <h4 className="mb-2 descpt">Description</h4>
                  <p style={{ whiteSpace: "pre-line" }}>{ProductData.description}</p>
                </div>
                <hr></hr>

                <div className="social_des">
                  <ul>
                    <h4 className="mb-2 descpt">Share on</h4>
                    <li>
                      <a href={`${facebook}${process.env.REACT_APP_BASE_URL}${`detail-product/${ProductData.id}`}`}>
                        <span><i class="fab fa-facebook-f icon"></i></span>
                        Facebook
                      </a>

                    </li>
                    <li>
                      <a href={`${pintrest}${process.env.REACT_APP_BASE_URL}${`detail-product/${ProductData.id}`}`}>
                        <span><i class="fab fa-pinterest icon"></i></span>
                        Pinterest
                      </a>

                    </li>
                    <li>

                      <a href={`${twitter}${process.env.REACT_APP_BASE_URL}${`detail-product/${ProductData.id}`}`}>
                        <span><i class="fab fa-twitter icon"></i></span>
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a href={`${mail}${process.env.REACT_APP_BASE_URL}${`detail-product/${ProductData.id}`}`}>
                        <span><i class="fa fa-envelope icon" aria-hidden="true"></i></span>
                        Mail
                      </a>

                    </li>
                  </ul>
                  {(() => {
                    if (checkAuthToken != null) {

                      return (

                        <>

                          {(() => {
                            if (ProductData?.marksold == '1') {
                              return (
                                <Button className="btn btn_submitnone w-100 rounded-0 nnn " style={{ backgroundColor: 'red', borderColor: 'red', cursor: "auto" }}>
                                  Sold
                                </Button>
                              )
                            } else {
                              return (

                                <Button className="btn 
                                  w-100 rounded-0 nnn "  disabled={setdisable} onClick={handleShow}>
                                  Enquiry Now
                                </Button>

                              )

                            }
                          })()}







                          <Modal show={show} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Enquiry Form</Modal.Title>
                            </Modal.Header>
                            <form className="product_detail_popup" onSubmit={handleSubmit(SubmitForm)}>
                              <Modal.Body>

                                <div className="product_tab">

                                  <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
                                    <Tab eventKey="home" title="For Rent">
                                      <div className="tab_content_inner">
                                        <>
                                          {/* <h2 className="mb-3">
                                          {(() => {
                                            if (ProductData.show_on_front == 1 && ProductData.price != null) {
                                              return (
                                                <h5>Price: ${ProductData.price}</h5>
                                              )
                                            }
                                          })()}
                                        </h2>
                                        */}

                                          {/* <Select options={options} /> 
                                        minLength: {
                                            value: 10,
                                            message: "Description should contain atleast 10 characters"
                                          },
                                          maxLength: {
                                            value: 400,
                                            message: "Description should not exceed 400 characters"
                                          }
                                        */}
                                        </>
                                        <Row >
                                          <Col lg={12} className="mb-3" >
                                            <textarea name="comment" placeholder="Comment" autoComplete="off"
                                              className="form-control col-md-12" style={{ height: '20vh' }}
                                              onChange={getCommantValues}
                                              ref={register({
                                                required: 'Please enter description'
                                              })} />

                                          </Col>
                                        </Row>

                                        <>
                                          {/*<Row>
                                          <Col lg={12} className="mb-3">
                                            <FormGroup>
                                              <label>Quantity</label>
                                              <input type="text" name="units" defaultValue={ProductData.units} onKeyUp={(e) => { changeUnit(e.target.value) }} className="form-control" placeholder=""
                                                ref={register({ required: 'Please enter quantity' })} />
                                              <p className="text-danger marginmessage">
                                                <ErrorMessage errors={errors} name="units">
                                                  {({ message }) => <p className={"text-danger"}>{message}</p>}
                                                </ErrorMessage></p>
                                            </FormGroup>
                                          </Col>
                                          <Col lg={6} md={6} sm={6}>
                                            <input type="date" name="start_date" className="form-control" placeholderText="start Date"
                                              onChange={date => setStartDate(date)} ref={register({ required: 'Please select start date' })} />

                                            <p className="text-danger marginmessage">
                                              <ErrorMessage errors={errors} name="start_date">
                                                {({ message }) => <p className={"text-danger"}>{message}</p>}
                                              </ErrorMessage></p>
                                          </Col>
                                          <Col lg={6} md={6} sm={6}>
                                            <input type="date" name="end_date" placeholderText="End Date" className="form-control"
                                              onChange={date => setEndDate(date)} ref={register({ required: 'Please select end date' })} />
                                            <p className="text-danger marginmessage">
                                              <ErrorMessage errors={errors} name="end_date">
                                                {({ message }) => <p className={"text-danger"}>{message}</p>}
                                              </ErrorMessage></p>

                                          </Col>
                                        </Row>*/}
                                        </>

                                        <Button variant="primary" type="submit" onClick={SubmitForm('Rent')}
                                          className="btn btn_submit w-100  btn btn-primary"
                                          disabled={visible}
                                          style={visible ? { cursor: "not-allowed" } : {}}
                                        >
                                          SUBMIT
                                          {visible && (
                                            <i
                                              className="fa fa-spinner fa-spin"
                                              style={{
                                                color: "#fff",
                                                fontSize: "18px",
                                                marginLeft: "10px",
                                              }}
                                            />
                                          )}
                                        </Button>

                                      </div>
                                    </Tab>

                                    <Tab eventKey="profile" title="For Buy" >
                                      <div className="tab_content_inner">

                                        <Row>
                                          <Col lg={12} className="mb-3" >
                                            <textarea name="comment" placeholder="Comment" autoComplete="off"
                                              className="form-control col-md-12" style={{ height: '20vh' }} onChange={getCommantValues} ref={register({
                                                required: 'Please enter description'
                                              })} />

                                          </Col>
                                        </Row>

                                        <Button variant="primary" onClick={SubmitForm('Buy')} className="btn btn_submit w-100  btn btn-primary"
                                          disabled={visible}
                                          style={visible ? { cursor: "not-allowed" } : {}}
                                        >
                                          SUBMIT
                                          {visible && (
                                            <i
                                              className="fa fa-spinner fa-spin"
                                              style={{
                                                color: "#fff",
                                                fontSize: "18px",
                                                marginLeft: "10px",
                                              }}
                                            />
                                          )}
                                        </Button>
                                      </div>
                                    </Tab>
                                  </Tabs>

                                </div>

                              </Modal.Body>

                            </form>
                          </Modal>
                        </>

                      )
                    }
                    else {
                      return (
                        <a href={`/login`} className="btn btn_submit w-100 rounded-0" >Enquire Now</a>




                      )
                    }
                  })()}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>



      <div className="product_model">



      </div>

    </>


  );
}
export default ProductDetails;
