import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import { useLocation, useHistory } from "react-router-dom";
import useSession from 'react-session-hook';
const Equipment = (props) => {
  const { settings, number } = props;
  const session = useSession();
  const location = useLocation();
  const [products, setProduct] = useState([]);
  const [isserach, setIsserach] = useState(false);
  const [visible, setVisibale] = useState(false);
  const [imgurl, setImageUrl] = useState('');
  const [token] = useState(session.token);
  const [query, setQuery] = useState({});
  const [bannerPath, setBannerPath] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const MAX_LENGTH = 30;
  const getProductData = async () => {
    query["itemsPerPage"] = 8
    let queryString = Helper.serialize(query);
    let path = apiUrl.product_listing + `?${queryString}`;

    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {

        setProduct(res.results.docs || []);

        setIsserach(false);
        setVisibale(false);
        setImageUrl(res.imgurl);
      } else {
        //alert.error(res.msg);
        setIsserach(false); setVisibale(false);
      }
    } else {
      //alert.error(res.error);
      setIsserach(false); setVisibale(false);
    }
  }
  useEffect(() => {
    getProductData()
  }, []);
  return (
    <>
      <Container>

        <Row>
          <Col lg={12} className="p-1">
            <div className="inner_heading d-flex align-items-center">
              <h2 className="mb-4">
              Equipment for Rental / Sale
              </h2>
              <a href={`/listing`} className="ml-auto">See All products<i class="fas fa-angle-double-right ml-1"></i></a>
            </div>
          </Col>
          {products.map((item, key) => {
            return (
              <Col lg={3} className="p-md-0 mb-3">
                <div className="product_content">
                  <div className="product_inner_content equipment_images">
                    <div>
                      <img src={`${imgurl}${item.primary_image}`} className="img-fluid" />
                      {(() => {
                      if (item.marksold == 1) {
                        return (
                          <div className="sold_tag">Sold</div>
                        )
                      }
                    })()}
                    </div>
                    <div className="product_content_cn">
                      <h3>{item.product_name}</h3>
                      {item.description.length > MAX_LENGTH ?
                        (
                          <p> {`${item.description.substring(0, MAX_LENGTH)}...`}</p>
                        ) :
                        <p>{item.description}</p>
                      }
                      <a href={`/detail-product/${item.id}`} className="btn-change7">Enquire Now</a>
                    </div>
                  </div>
                </div>
              </Col>);
          })}




        </Row>
      </Container>

    </>
  );
}
export default Equipment;
