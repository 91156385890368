import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';



const FAQ = () => {
  const session = useSession();
  const [token] = useState(session.token);
  const [data, setData] = useState('');

  const getData = async () => {
    const path = apiUrl.getFaq;
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      setData(res.data.docs);
    } else {
      alert.error(res.error);
    }
  }


  useEffect(() => {
    // pageData();
    getData()
  }, []);



  return (
    <>
  
      <Container>
        <Row>

          <div className="col-12 col-md-9 mx-auto faq_sec pb-5">
            <div className="text-center py-5 faq_heading">
              <h5 className="m-0 title">FAQ’s</h5>
              <p className="m-0">A few years and thousands of satisfied customers later, we could justifiably call it a success</p>
            </div>

            <>
              {/* <Accordion allowZeroExpanded>

              <AccordionItem key={0}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    It is a long established fact that a reader will be distracted by the readable content
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="m-0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    some form, by injected humour, or randomised words which don't look even.</p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem key={1}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    There are many variations of passages of Lorem Ipsum available, but the majority.
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="m-0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    some form, by injected humour, or randomised words which don't look even.</p>
                </AccordionItemPanel>
              </AccordionItem>


              <AccordionItem key={2}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots.
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="m-0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    some form, by injected humour, or randomised words which don't look even.</p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem key={3}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Simply dummy text of the printing and typesetting industry.
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="m-0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    some form, by injected humour, or randomised words which don't look even.</p>
                </AccordionItemPanel>
              </AccordionItem>


              <AccordionItem key={4}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Simply dummy text of the printing and typesetting industry.
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="m-0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    some form, by injected humour, or randomised words which don't look even.</p>
                </AccordionItemPanel>
              </AccordionItem>


              <AccordionItem key={5}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Simply dummy text of the printing and typesetting industry.
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="m-0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    some form, by injected humour, or randomised words which don't look even.</p>
                </AccordionItemPanel>
              </AccordionItem>

            </Accordion> */}
            </>

            {/* {data && console.log('item',data.map(x=>x))} */}
            <Accordion allowZeroExpanded>
            { data && 
              data.map(item=>(
                <AccordionItem key={3}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                   {item.question}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="m-0">{item.answer}</p>
                </AccordionItemPanel>
              </AccordionItem>
              ))
            }
              


            </Accordion>


          </div>
        </Row>
      </Container>
    </>
  );
}
export default FAQ;
