import React, { useState, useEffect } from "react";
import {Container, Row,Col,FormGroup,Input,Button,Label,Form } from "reactstrap";
import { ErrorMessage,useForm } from 'react-hook-form';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory,Redirect,Link } from "react-router-dom";
import { useAlert } from 'react-alert';


const ForgetPassword = (props) => { 
   const session = useSession();
   const alert = useAlert();
   const { register, handleSubmit, errors , watch } = useForm({ mode: "onSubmit"});
   const [token] = useState(session.token);
   const [loading, setLoading] = useState(false);
   let history = useHistory();
   const [visible, setVisibale] = useState(false);
 
   const onSubmit = async (data,e) => {
    setVisibale(true);
    let postJson = { email: data.email, user_type: 'user' };
    let path = apiUrl.forgetPassword;
    const fr = await Helper.post(postJson, path);
    const response_data = await fr.response.json();
    
    if (fr.status === 200) {
      if (response_data.success) {
        alert.success(response_data.msg);
        setVisibale(false);
        e.target.reset();      
      } else {
        alert.error(response_data.msg);
        setVisibale(false);
      }
    } else {
      alert.error(response_data.error);
      setVisibale(false);
    }
  };

 
   return (
    <>
      <section
        className="login_signup_sec py-4 py-md-5"
       
      >
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col xs={12} md={7} lg={5} xl={6}>
              <div className="login_signup_block p-4">
                <div className="form_logo text-center mb-5">
                  <img src="./assets/img/logo.png" alt="Trading Oilfield" />
                </div>

                    <Form className="login_signup_form" onSubmit={handleSubmit(onSubmit)}>
                      <FormGroup>
                      <label>Email Address</label>
                      <input type="email" name="email" className="form-control"  placeholder="" ref={register({ required: "Please enter email address",pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                      } })}/>
                   <p className="text-danger marginmessage">
                  <ErrorMessage errors={errors} name="email" >
                      {({ message }) => <p className="text-danger">{message}</p>}
                    </ErrorMessage></p>
                      </FormGroup>
                      <input type="submit" className="form-control btn" value="Submit"/>

                      <div className="dont_signup_text text-center py-5">
                        <p>
                          Want to go back? <Link to="/login">Login</Link>
                        </p>
                      </div>
                    </Form>
                 
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ForgetPassword;
