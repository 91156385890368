import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import Slider from "react-slick";
import { Settings, Settings1, Settings2, Settings3 } from "./BannerSettings";
import apiUrl from '../../constants/apiPath';
import Helper from '../../constants/helper';
import parse from 'html-react-parser';
import { form } from "reactstrap";
import '../../assets/css/app.css'

const AboutUs = () => {
  console.log('about us page called------>>>>')

  const [staticData, setStaticData] = useState('')
  const getData = async () => {
    try {
      let path = apiUrl.getStaticPage + 'about-us';
      const fr = await Helper.get(path);
      const res = await fr.response.json();
      setStaticData(res.result.content)
    } catch (error) {
      console.log('error', error)
    }
  }


  useEffect(() => {
    getData()
  }, [])


  const prepareHtml = (description) => {
    if (description) {
      console.log('description', description)
      return { __html: description || "" };
    }

    return { __html: "" };
  };


  return (
    <>
      {parse(staticData)}

<div className="mb-4 mb-sm-5">
      <Container>
      {/*<div class="about_sec_com text-center mb-4">
        <h2>About Company</h2>
  </div>*/}
        <Row>
      <Col md={6} className="text-center mb-2 mb-md-0">
        <img src="/assets/img/abt1.jpg" className="img-fluid" />
        </Col>
        <Col md={6} className="text-center">
        <img src="/assets/img/about-us-2.png" className="img-fluid" />
        </Col>
        </Row>
      </Container>
      </div>

      {/* {console.log('data->>>', parse(staticData))} */}





      {/* <section className="main" >
        <div className="Container" >
          <Row>
            <Col lg={12}>
              <div className="text-center about_sec_com">
                <h2 style={{ fontWeight: 600, fontSize: '30px', marginBottom: 50 }}>
                  About Company
                </h2>
                <p>A few years and thousands of satisfied customers later, we could justifiably call it a success</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="our_team_sec">
        <div className="Container">
          <Row>
            <Col lg="12">
              <div class="circle">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
              <div className="text-center team_heading_sec">
                <h2>Our Team</h2>
                <p>sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
                  attributed to an unknown typesetter in the 15th century who is thought to have scrambled</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="team_photo_sec">
        <div className="Container">
          <Row>
            <Col lg={12}>
              <div className="d-block d-md-none">
                <Slider {...Settings1}>
                  <div className="service_icon ">
                    <img src="./assets/img/team1.png" alt="team" />
                  </div>
                  <div className="service_icon">
                    <img src="./assets/img/team2.png" alt="team" />
                  </div>
                  <div className="service_icon active">
                    <img src="./assets/img/team3.png" alt="team" />
                  </div>
                  <div className="service_icon">
                    <img src="./assets/img/team4.png" alt="team" />
                  </div>
                  <div className="service_icon">
                    <img src="./assets/img/team5.png" alt="team" />
                  </div>
                  <div className="service_icon">
                    <img src="./assets/img/team6.png" alt="team" />
                  </div>
                  <div className="service_icon">
                    <img src="./assets/img/team1.png" alt="team" />

                  </div>

                </Slider>

              </div>


              <div className="d-none d-md-block desktop_team_slide">
                <div className="d-flex db_team">
                  <div className="mt-5">

                    <img src="./assets/img/team1.png" alt="team" />
                  </div>
                  <div className="">

                    <img src="./assets/img/team2.png" alt="team" />
                  </div>
                  <div className="mt-5">

                    <img src="./assets/img/team3.png" alt="team" />
                  </div>
                  <div className="mt-4">

                    <img src="./assets/img/team4.png" alt="team" />
                  </div>
                  <div className="mt-5">

                    <img src="./assets/img/team5.png" alt="team" />
                  </div>
                  <div>

                    <img src="./assets/img/team6.png" alt="team" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

      </section>

      <section className="marketplace_sec">
        <div className="Container">
          <div class="inner_heading text-center"><h2 class="mb-4">ARTCO Marketplace in Numbers</h2>
          </div>
          <Row>
            <Col lg={4} md={4} sm={4}>

              <div className="marketplace_inner_col">
                <h2>
                  500+
                </h2>
                <h4>
                  Companies registered
                </h4>
              </div>

            </Col>
            <Col lg={4} md={4} sm={4}>

              <div className="marketplace_inner_col">
                <h2>
                  $100 MM
                </h2>
                <h4>
                  Of equipment listed
                </h4>
              </div>

            </Col>
            <Col lg={4} md={4} sm={4}>

              <div className="marketplace_inner_col">
                <h2>
                  2000's
                </h2>
                <h4>
                  Transactions initiated
                </h4>
              </div>

            </Col>
          </Row>
        </div>
      </section>

      <section className="story_sec">
        <div className="Container">
          <Row>
            <Col lg={6} md={12}>
              <div className="story_inner">
                <h2>
                  Our Story
                </h2>
                <hr></hr>
                <div>
                  <p>
                    It is a long established fact that a reader will be distracted by the readable
                    a page when looking at its layout. The point of using Lorem Ipsum is that it has
                    a more-or-less normal distribution of letters.
                  </p>

                  <p className="py-0 py-md-3">

                    reader will be distracted by the readable a page when looking at its layout.
                    The point of using Lorem Ipsum is that it has a 27 Loyang Crescent Loyang
                    Offshore Supply Base Blk101, #01-02, Singapore.
                  </p>

                  <p>

                    There are many variations of passages of Lorem Ipsum available, but the majority
                    have suffered alteration in some form, by injected humour, or randomised
                    words which don't look even slightly believable.
                  </p>
                </div>

              </div>
            </Col>

            <Col lg={6} md={12} className="db_team">
              <div>

                <img src="./assets/img/story_image.png" alt="team" />
              </div>

            </Col>
          </Row>
        </div>

      </section> */}


    </>
  );
}
export default AboutUs;
