export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const LOADED_USER_FORGOT_SUCCESS = "LOADED_USER_FORGOT_SUCCESS";
export const LOADED_CUSTOMER_DATA_SUCCESS = "LOADED_CUSTOMER_DATA_SUCCESS";
export const LOADED_DATA_SUCCESS = "LOADED_DATA_SUCCESS";
export const LOADED_SETTING_INFO_SUCCESS = "LOADED_SETTING_INFO_SUCCESS";
export const LOADED_BRAND_DATA_SUCCESS = "LOADED_BRAND_DATA_SUCCESS";
export const LOAD_FAQ_DATA_SUCCESS = "LOAD_FAQ_DATA_SUCCESS";
export const LOAD_HOME_PAGE_DATA_SUCCESS = "LOAD_HOME_PAGE_DATA_SUCCESS";
export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const LOAD_LEADER_DATA_SUCCESS = "LOAD_LEADER_DATA_SUCCESS";

export const LOAD_FILTER_DATA_SUCCESS = "LOAD_FILTER_DATA_SUCCESS";
export const LOADED_USER_REGISTER_SUCCESS = "LOADED_USER_REGISTER_SUCCESS";
export const LOADED_UPDATE_PROFILE_SUCCESS = "LOADED_UPDATE_PROFILE_SUCCESS";
export const LOAD_MENTORS_DATA_SUCCESS = "LOAD_MENTORS_DATA_SUCCESS";
export const LOAD_FORM_POP = "LOAD_FORM_POP";
export const LOAD_PAGINATION_DATA_SUCCESS = "LOAD_PAGINATION_DATA_SUCCESS";
export const LOAD_REGISTER_DATA_SUCCESS = "LOAD_REGISTER_DATA_SUCCESS";
export const LOADED_MENTEE_REQUEST_DATA = "LOADED_MENTEE_REQUEST_DATA";
