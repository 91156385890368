import { combineReducers } from "redux";

import initialState from "./initialState";
import isAuth from "./isAuthRequest";
import isSubmitting from "./isSubmittingRequest";
import isFrogot from "./isSubmittingRequest";
import setting from "./settingReducer";
import brand from "./brandReducer";
import homePageData from "./homePageReducers";
import leaders from "./leaderReducer";
import isFetching from "./networkRequest";
import faqData from "./faqDataReducer";
import filter from "./filterReducer";
import redirectTo from "./redirectTo";
import userInfo from "./userInfoReducer";
import updateProfileData from "./updateProfileDataReducer";
import userRegister from "./registerDataReducer";
import mentorsData from "./mentorDataReducer";
import menteeRequestData from "./menteeRequestDataReducer";
const rootReducer = combineReducers({
  isAuth,
  isSubmitting,
  isFrogot,
  setting,
  brand,
  homePageData,
  leaders,
  isFetching,
  faqData,
  filter,
  redirectTo,
  userInfo,
  updateProfileData,
  userRegister,
  mentorsData,
  menteeRequestData,
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
