import React, { useState, useEffect } from "react";
import ProfileHeader from "./Action/ProfileHeader";
import ProfileSidebar from "./Action/ProfileSidebar";
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory,useLocation } from "react-router-dom";
import { useAlert } from 'react-alert';

const Dashboard = (props) => { 
  const [UserData, setData] = useState({});
  const session = useSession();
   const [token] = useState(session.token);
   const location = useLocation();
   const alert = useAlert();
   const [id, setId] = useState('');
   const checkAuthToken = localStorage.getItem("usertoken");
   const [visible, setVisibale] = useState(false);
   const [activepage] = useState(1);
   const [superdata, setSuperdata] = useState([]);
  const [totalitems, setTotalItems] = useState('');
  const [imgurl, setimageurl] = useState('');
  const [keywords, setKeyWords] = useState('');
  const [isserach, setIsserach] = useState(false);
   let history = useHistory();
   if (checkAuthToken==null) {
    history.push("/login");
  }

  const getData = async () => {
    const user_Id = localStorage.getItem('userid');
    let path = apiUrl.get_user + '/' + `${user_Id}`;
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
        if (res.success) {

            setId(res.results.id);
            setData(res.results);
            console.log(res.results.image)
            let imgurl=res.results.image
            
        } else {
            console.log(res.msg);
        }
    } else {
        console.log(res.msg);
    }

};
    function SidebarAddClass() {
      document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
    }
    
   
   
    function SidebarRemoveClass(){
      console.log("tset")
       document.getElementById("OnscrollBody").classList.remove("show_sidebar");
    }

    useEffect(() => {
     
      getData()
    }, []);
    return (
      
      <div>  
  <ProfileHeader item={UserData}/>
      <div className="dashboard_outer">
      <ProfileSidebar item={UserData}/>
           <div className="dashboard_inner">
                  <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                    <a href="javascript:void(0);" onClick={SidebarAddClass} className="toggle_btn d-lg-none"><i className="fas fa-bars"></i></a>
                    </div>


               <div className="dash_main_inner">

            <div className="row align-items-center">
              <div className="col-sm-5">
              <h2 className="dash_heading pl-3 pl-md-0">Dashboard</h2>
              </div>
              
              
            </div>


         {/* notify */}




{/* notify */}


               </div>






           </div>


           
      </div> 











      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>


      </div>
    );
  
}
export default Dashboard;
