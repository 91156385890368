// CMS Page Without Auth
import Home from "../Pages/Home";
import AboutUs from "../Pages/Home/AboutUs";
import ContactUs from "../Pages/Home/ContactUs";
import Equipment from "../Pages/Home/EquipmentPage";
import Certification from "../Pages/Home/Certification";
import FAQ from "../Pages/Home/FAQ";
import Register from "../Pages/Register";
import Login from "../Pages/Login";
import ForgetPassword from "../Pages/Login/ForgetPassword";
import myProfile from "../Pages/MyProfile/MyProfile";
import History from "../Pages/MyProfile/History";
import ChangePassword from "../Pages/MyProfile/Changepassword";
import Listing from "../Pages/Products/listing";
import productDetail from "../Pages/Products/productDetail";
import enquiry from "../Pages/Products/enquiry";
import ResetPassword from "../Pages/Login/ResetPassword";
import Dashboard from "../Pages/MyProfile/Dashboard";
import Notification from "../Pages/MyProfile/Notification";
import TermsCondition from "../Pages/Home/TermsCondition";
//Without Auth

// After login
export const RoutesPage = [
  { path: "/", name: "Home", component: Home, title: "Oilfield" },  
  { path: "/about-us", name: "About-Us", component: AboutUs, title: "About-us" },
  { path: "/contact-us", name: "Contact-Us", component: ContactUs, title: "Contact-us" },
  {path: "/equipment", name: "Equipment", component: Equipment, title: "Equipment" },
  {path: "/certification", name: "Certification", component: Certification, title: "Certification" },
  { path: "/faq", name: "FAQ", component: FAQ, title: "FAQ" },
  { path: "/listing", name: "Product-listing", component: Listing, title: "Product-listing" },
  { path: '/detail-product/:id', name: 'Product Detail', component: productDetail,Module:'Product Detail' },
  { path: '/enquiry/:id', name: 'Enquiry', component: enquiry,Module:'Enquiry' },
  { path: "/register", name: "Register", component: Register, title: "Register" },
  { path: "/login", name: "Login", component: Login, title: "Login" },
  { path: "/forget-password", name: "Forget Password", component: ForgetPassword, title: "Forget Password" },
  { path: '/reset-password/:token', name: 'Reset Password', component: ResetPassword,Module:'Reset Password' },
  {path: "/terms-condition", name: "TermsCondition", component: TermsCondition, title: "TermsCondition" },
];

export const RoutesAuth = [
  
  { path: "/my-profile", name: "myProfile", component: myProfile, title: "My-Profile" },
  { path: "/change-password", name: "change password", component: ChangePassword, title: "Change Password" },
  { path: "/history", name: "History", component: History, title: "History" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard, title: "Dashboard" },
  { path: "/notification", name: "Notification", component: Notification, title: "Notification" },
];

export const RoutesUser = [
 
];
