import React from "react";
import { Route, Switch } from "react-router-dom";
import { RoutesPage, RoutesAuth, RoutesUser } from "../Route/route";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

const RouteWithHeader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Header/>
            <Component {...props} title={rest.title} />
          <Footer/>
        </>
      )}
    />
  );
};
const RouteWithAuth = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} title={rest.title} />
        </>
      )}
    />
  );
};
const Index = (props) => {
  return (
    <Switch>
      {RoutesPage.map((route, index) => (
        <RouteWithHeader
          key={index}
          exact
          path={route.path}
          component={route.component}
          title={route.title}
        />
      ))}
       {RoutesAuth.map((route, index) => (
        <RouteWithAuth
          key={index}
          exact
          path={route.path}
          component={route.component}
          title={route.title}
        />
      ))}
    </Switch>
  );
};

export default Index;
