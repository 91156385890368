import React, { useState,useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Button, Card, Accordion,Dropdown } from 'react-bootstrap';
import ProfileHeader from "./Action/ProfileHeader";
import ProfileSidebar from "./Action/ProfileSidebar";
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory,useLocation } from "react-router-dom";
import { useAlert } from 'react-alert';
import Helper from '../../constants/helper';
import moment from "moment";
import Pagination from "react-js-pagination";
const Notification = (props) => { 
   const session = useSession();
   const [token] = useState(session.token);
   const location = useLocation();
   const alert = useAlert();
   const [profileImage, setProfileImage] = useState('');
   const checkAuthToken = localStorage.getItem("usertoken");
   const [id, setId] = useState('');
   const [loading, setLoading] = useState(false);
   const [UserData, setData] = useState({});
   const [preview, setProfilePicPreview] = useState('');
   const [superdata, setSuperdata] = useState([]);
   const [totalitems, setTotalItems] = useState('');
   const [visible, setVisibale] = useState(false);
   const [notifyCount, setnotifyCount] = useState('');
   const [activepage] = useState(1);

   let history = useHistory();
   if (checkAuthToken==null) {
    history.push("/login");
  }
    function SidebarAddClass() {
      document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
    }
    
   
   
    function SidebarRemoveClass(){
      console.log("tset")
       document.getElementById("OnscrollBody").classList.remove("show_sidebar");
    }
    const getData = async () => {
      const user_Id = localStorage.getItem('userid');
      let path = apiUrl.get_user + '/' + `${user_Id}`;
      const fr = await Helper.get(path, token);
      const res = await fr.response.json();
      if (fr.status === 200) {
          if (res.success) {
  
              setId(res.results.id);
              setProfilePicPreview(res.results.image);
              setData(res.results);
              
              console.log(res.results.image)
              let imgurl=res.results.image
              setProfileImage(imgurl)
          } else {
              console.log(res.msg);
          }
      } else {
          console.log(res.msg);
      }
  
  };
  const getNotificationData = async (page = activepage) => {
    setVisibale(true);
    const itemsPerPage = 10;
    const user_Id = localStorage.getItem('userid');
    
    let path = apiUrl.getNotification + '?id=' +user_Id +'&page=' + `${page}` + '&itemsPerPage=' + `${itemsPerPage}`;
    console.log(path);
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    console.log(res.results);
    if (fr.status === 200) {
      if (res.success) {
        console.log(res.results.docs);
        setSuperdata(res.results.docs || []);
        setTotalItems(res.results.totalDocs);
        setVisibale(false)
        
      } else {
       alert.error(res.msg)
        setVisibale(false)
      }
    } else {
      alert.error(res.error)
      setVisibale(false)
    }

  };
  useEffect(() => {
    getData()
  getNotificationData()
  }, []);  

    return (
      
      <div>   
 <ProfileHeader item={UserData}/>
      <div className="dashboard_outer">
      <ProfileSidebar item={UserData} count={notifyCount}/>
{/* dashbaord-inner */}


<div className="dashboard_inner">
<div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                    <a href="javascript:void(0);" onClick={SidebarAddClass} className="toggle_btn d-lg-none"><i className="fas fa-bars"></i></a>
                    </div>
<div className="dash_main_inner same_vh">

<div className="row align-items-center">
              <div className="col-sm-5">
              <h2 className="dash_heading pl-3 pl-md-0">Notification</h2>
              </div>
              </div>


{/* notification */}
{(() => {
  if (superdata.length==0 ) {
    return (
      <label className="noRecord">No Record Found !</label>
    )
  }
})()}

<div className="main_notification1">
{superdata.map((item, key) => {

return ( 
<div className="d-flex new_notifcation" key={item.id}>
<div className="notification_name">
<div className="notify_user d-flex align-items-center">
<div className="d-flex align-items-center name_user">
<h2 className="mr-3">{item.type}</h2>
{item.is_read==0 && <span>New</span>}
</div>
<div className="time ml-sm-auto">
<h3>{moment(item.created_at).format('LLL')}</h3>
</div>
</div>
<p className="m-0 py-2">
{item.description}
</p>

</div>

</div>)

 
})}







</div>

{/* notification */}



{/* pagination */}

<div className="pagination_deshbaord d-flex justify-content-center justify-content-md-end">
 
{superdata !== 0 && <div className="show-pagination technician-page">
      <Pagination
        activeClass={""}
        activeLinkClass={"page-link active"}
        itemClass={"page-item"}
        linkClass={"page-link"}
        activePage={activepage}
        itemsCountPerPage={10} 
        totalItemsCount={totalitems}
        pageRangeDisplayed={4}
        prevPageText="Previous"
        nextPageText="Next"
        firstPageText="<"
        lastPageText=">"
        onChange={getData}
      />
    </div>}
</div>

{/* pagination */}

</div>

</div>

{/* dashbaord-inner */}





      </div> 


      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>


      </div>
    );
  }
  export default Notification;
