let apiUrl = {

  /* Admin Dashboard APIs */

  get_banners: 'api/front/get-banners',
  add_user: 'api/front/add-user',
  userLogin: 'api/front/userlogin',

  product_listing: 'api/front/products',
  get_product: 'api/front/get-product',
  get_category_subcategory: "api/front/get-subcategory-value",
  get_products_name: "api/front/get-products-name",
  //get_products: 'api/front/products',
  get_category_value: 'api/front/get-category-value',
  forgetPassword: 'api/front/forget-password',
  resetPassword: 'api/front/reset-password',
  sendEnquiry: 'api/front/send-enquiry',
  contactEnquiry: 'api/front/contact-enquiry',

  get_user: 'api/front/get-user',
  update_user: 'api/front/update-user',
  update_profile_pic: 'api/front/update-profile-pic',
  changePassword: 'api/front/change-password',
  getUserEnquiry: 'api/front/user-enquiry',
  get_category: 'api/front/category',
  get_top_product: 'api/front/top-products',
  getNotification: 'api/front/notifications',
  setNotificationRead: 'api/front/setNotificationRead',


  getAccessToken: 'api/front/get_access_token',

  getStaticPage: 'api/admin/get-page-by-slug/',
  getFaq: 'api/admin/faq-list-user',
}
export default apiUrl;
