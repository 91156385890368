// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isAuth: false,
  isSubmitting: false,
  isFetching: false,
  userInfo: {},
  profileData: {},
  updateProfileData: {},
  isFrogot: false,
  setting: {},
  brand: [],
  homePageData: {},
  mentorsData: [],
  leaders: [],
  faqData: [],
  filter: {},
  userRegister: {},
  menteeRequestData: [],
};
