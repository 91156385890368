import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Input, Button, Form, Label, } from "reactstrap";
import { ErrorMessage, useForm } from 'react-hook-form';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory, Redirect } from "react-router-dom";
//import { useAlert } from 'react-alert';

import { LinkedIn } from 'react-linkedin-login-oauth2';


const Login = (props) => {
  const session = useSession();
  //const alert = useAlert();

  const [REDIRECTURI] = useState(process.env.REACT_APP_REDIRECTURI);
  const [CLIENTID] = useState(process.env.REACT_APP_CLIENTID);
  const { register, handleSubmit, errors, watch } = useForm({ mode: "onSubmit" });
  const [token] = useState(session.token);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [visible, setVisibale] = useState(false);

  const [code, setCode] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');



  const handleSuccess = async (data) => {

    console.log("data", data)

    setCode(data.code);
    setErrorMessage('')
    let postJson = { code: data.code };
    let path = apiUrl.getAccessToken;
    const fr = await Helper.post(postJson, path);
    const response_data = await fr.response.json();
    if (fr.status === 200) {
      if (response_data.success) {
        if (response_data.msg == 'NEW_USER') {
          console.log("New User", response_data);
          props.history.push({
            pathname: '/register',
            state: response_data // your data array of objects
          })
        } else {
          //console.log("Old User", response_data);
          setVisibale(false);
          session.setSession({ token: response_data.token });
          let profile = parseJwt(response_data.token);
          localStorage.setItem('user_type', profile.user_type);
          localStorage.setItem('usertoken', response_data.token);
          localStorage.setItem('userid', response_data.result.id);
          props.history.push('/my-profile');
        }
      } else {
        setVisibale(false);
      }
    } else {
      // alert.error(response_data.error);
      setVisibale(false);
    }
    // var url = "https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=86es3mndgnxmc7&client_secret=8S9E5uWxujAJSSNc&code=" + data.code + "&redirect_uri=http://localhost:3000/login";

  }

  const handleFailure = (error) => {
    setCode('');
    setErrorMessage(error.errorMessage)
  }


  function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  };

  const onSubmit = async (data) => {
    setVisibale(true);
    let postJson = { email: data.email, password: data.password, user_type: 'user' };
    let path = apiUrl.userLogin;
    const fr = await Helper.post(postJson, path);
    const response_data = await fr.response.json();

    if (fr.status === 200) {
      if (response_data.success) {
        //alert.success(response_data.msg);
        setVisibale(false);
        session.setSession({ token: response_data.token });
        let profile = parseJwt(response_data.token);
        localStorage.setItem('user_type', profile.user_type);
        localStorage.setItem('usertoken', response_data.token);
        localStorage.setItem('userid', response_data.result.id);
        //props.history.push('/my-profile');
        props.history.push('/');
      } else {
        //alert.error(response_data.msg);
        setVisibale(false);
      }
    } else {
      // alert.error(response_data.error);
      setVisibale(false);
    }
  };

  if (session.isAuthenticated && session.profile.user_type == "user") {
    // history.push("/dashboard");

    return (
      <Redirect to='/my-profile' />
    )

  }
  return (
    <>
      <section className="login_signup_sec py-4 py-md-5">
        <Container className="py-0 py-md-5">


          <Form className="login_signup_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center mobile_dflex mb-2 mb-md-4">
              <div className="form_logo text-center ">
                <img src="./assets/img/logo.png" alt="" />
              </div>
              <div className="ml-auto login_text">
                <span>Welcome Back</span>
              </div>

            </div>


            <Row>

              <Col lg={12} className="p-1 p-md-2">
                <FormGroup>
                  <label>
                    Email Address
                  </label>
                  <input type="email" name="email" className="form-control" ref={register({ required: true })} />
                  {errors.email && <p className="text-danger marginmessage">Please enter email address</p>}
                </FormGroup>


              </Col>
              <Col lg={12} className="p-1 p-md-2">
                <FormGroup>
                  <label>
                    Password
                  </label>
                  <input type="password" name="password" className="form-control" ref={register({ required: true })} />
                  {errors.password && <p className="text-danger marginmessage">Please Enter Password</p>}
                </FormGroup>


              </Col>

              <Col lg={12} className="p-1 p-md-2">
                <FormGroup>
                  <div className="term_condition_checkbox d-flex">
                    <div>
                      <input id="myCheckbox2" type="checkbox" />
                      <label for="myCheckbox2">Remember Me</label>
                      <span></span>
                    </div>

                    <div className="ml-auto forget_password">
                      <a href="/forget-password">Forgot Password ?</a>
                    </div>
                  </div>
                </FormGroup>
              </Col>

              <Col lg={12} className="p-1 p-md-2 mt-2">
                <FormGroup>
                  <div>
                    <input type="submit" className="form-control btn" value="Login" />
                  </div>
                </FormGroup>
              </Col>
              <hr className="linkedinDiv" />
              <Col lg={12} className="p-1 p-md-2 mt-2 alignCen">
                <LinkedIn
                  clientId={CLIENTID}
                  onFailure={handleFailure}
                  onSuccess={handleSuccess}
                  scope={["r_liteprofile", "r_emailaddress"]}
                  redirectUri={REDIRECTURI}
                >
                  <img src="./assets/img/linkedin.png" alt="Log in with Linked In" style={{ maxWidth: '250px' }} />
                </LinkedIn>
                {/* {!code && <div>No code</div>}
                {code && <div>Code: {code}</div>}
                {accessToken && <div>accessToken: {accessToken}</div>}
                {errorMessage && <div>{errorMessage}</div>} */}
              </Col>
              <Col lg={12} className="p-1 p-md-2 mt-2">
                <FormGroup>

                  <div className="already_account text-center">
                    <p>Don't have an account? <a href={`/register`} className="same_color_term">Sign up</a></p>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>


        </Container>
      </section>
    </>
  );
}
export default Login;
