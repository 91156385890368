import React,{ useState, useEffect } from "react";
import {Container, Row,Col,FormGroup,Input,Button,Label,Form } from "reactstrap";
import {ErrorMessage, useForm } from 'react-hook-form';

import { useAlert } from 'react-alert';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useLocation,useHistory,Redirect,Link,useParams } from "react-router-dom";
const TermsCondition = (props) => { 
   const alert = useAlert();
   let history = useHistory();
   const [visible, setVisibale] = useState(false);
   const location = useLocation();
   const { register, handleSubmit,  errors , watch } = useForm({ mode: "onSubmit"});

 

  return (
    <>
      <section id="faq" class="faq space_top">
               <Container>
                  <div className="contact_heading text-center">
                    <h2>Terms & Conditions</h2>
                  </div>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                              </Container>
            </section>
        
      </>
    );  
}
export default TermsCondition;
