import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function updateProfileDataReducer(
  state = initialState.updateProfileData,
  action
) {
  switch (action.type) {
    case types.LOADED_UPDATE_PROFILE_SUCCESS:
      return action.updateProfileData;
    default:
      return state;
  }
}
