import React, { useState, useEffect } from "react";
import {Col, Container, Row,Form,FormGroup } from "reactstrap";

import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory,useLocation } from "react-router-dom";
import { ErrorMessage, useForm} from 'react-hook-form';

import { useAlert } from 'react-alert';
import { Button, Card, Accordion } from 'react-bootstrap';
import ProfileHeader from "./Action/ProfileHeader";
import ProfileSidebar from "./Action/ProfileSidebar";

const MyProfile = (props) => { 
   const session = useSession();
   const [token] = useState(session.token);
   const location = useLocation();
   const alert = useAlert();
   const [profileImage, setProfileImage] = useState('');
   const [UserData, setData] = useState({});
   const { register, handleSubmit, errors , watch} = useForm();
   const checkAuthToken = localStorage.getItem("usertoken");
   const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const [preview, setProfilePicPreview] = useState('');
    
   let history = useHistory();
   if (checkAuthToken==null) {
    history.push("/login");
  }
  const current_path=location.pathname;
   function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }
 
  const getData = async () => {
    const user_Id = localStorage.getItem('userid');
    let path = apiUrl.get_user + '/' + `${user_Id}`;
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
        if (res.success) {

            setId(res.results.id);
            setProfilePicPreview(res.results.image);
            setData(res.results);
            console.log(res.results.image)
            let imgurl=res.results.image
            setProfileImage(imgurl)
        } else {
            console.log(res.msg);
        }
    } else {
        console.log(res.msg);
    }

};


const onSubmit = async data => {
  
  setLoading(true);
  let formData = new FormData();
  let company_name=UserData.company_name
  if(data.company_name!=""){
    company_name=data.company_name;
  }
  console.log(data.company_name)
  let registration_number=UserData.registration_number
  if( data.registration_number!=""){
    registration_number=data.registration_number;
  }
  let first_name=UserData.first_name
  if( data.first_name!=""){
    first_name=data.first_name;
  }
  let email=UserData.email
  if( data.email!=""){
    email=data.email;
  }
 
  let contact_number=UserData.contact_number
  if( data.contact_number!=""){
    contact_number=data.contact_number;
  }
  let mobile=UserData.phone
  if( data.mobile!=""){
    mobile=data.mobile;
  }
  let postJson = { id: localStorage.getItem('userid'), company_name:company_name, registration_number: registration_number, name: first_name , email:email, contact_number:contact_number, phone: mobile };
  formData.append('data', JSON.stringify(postJson));
//  formData.append('profile_pic', profilePic);
  let path = apiUrl.update_user;
  const fr = await Helper.formPost(formData, path, token);
  const res = await fr.response.json();
  if (fr.status === 200) {
      if (res.success) {
          setLoading(false);
          alert.success(res.msg);
        
      } else {
          alert.error(res.msg);
          setLoading(false);
      }
  } else {
      alert.error(res.error);
      setLoading(false);
  }
};



useEffect(() => {
  getData()

}, []);

const Logout = () => {
  localStorage.clear("usertoken");
  localStorage.clear("userid");
  alert.success("User Loggged Out");
  props.history.push('/login');
 };
  function SidebarRemoveClass(){
    
     document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }
  return (
      
    <div>  
    <ProfileHeader item={UserData}/>

    <div className="dashboard_outer">
    <ProfileSidebar item={UserData}/>
{/* dashbaord-inner */}



<div className="dashboard_inner">
<div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                  <a href="#" onClick={SidebarAddClass} className="toggle_btn d-lg-none"><i className="fas fa-bars"></i></a>
                  </div>
<div className="dash_main_inner">

<div className="">
<div className="row align-items-center">
            <div className="col-sm-5">
            <h2 className="dash_heading pl-3 pl-md-0">My profile</h2>
            </div>
            </div>



<div className="change_password profile_details">
<React.Fragment>  
<form className="my_profile_form" onSubmit={handleSubmit(onSubmit)}>

<Row>
<Col lg ={6} md={6} sm={6}>

<label>Company Name</label>
<input type="text" className="form-control" id="company_name" placeholder="" defaultValue={UserData.company_name} name="company_name" 
ref={register({
  required: 'Please enter company name', 
  minLength: {
    value: 2,
    message: "Company name should contain atleast 2 characters"
  },
  maxLength: {
    value: 40,
    message: "Company name should not exceed 20 characters"
  }  })}/>
<p className="text-danger marginmessage">
<ErrorMessage errors={errors} name="company_name">
  {({ message }) => <p className={"text-danger"}>{message}</p>}
</ErrorMessage>
</p>

</Col>
<Col lg ={6} md={6} sm={6}>
<label>Company Registration Number</label>
<input type="text" className="form-control" id="registration_number" placeholder="" name="registration_number"  defaultValue={UserData.registration_number}
autoComplete="off" ref={register({ 
                required: "Please enter company registration number",
                 minLength: {
                  value: 2,
                  message: "Company registration number should contain atleast 2 characters"
                },
                maxLength: {
                  value: 20,
                  message: "Company registration number should not exceed 20 characters"
                }  })}/>
                <p className="text-danger marginmessage">
                <ErrorMessage errors={errors} name="registration_number">
                  {({ message }) => <p className={"text-danger"}>{message}</p>}
                </ErrorMessage>
              </p>
</Col>
<Col lg ={6} md={6} sm={6}>
<label>Name</label>
<input type="text" className="form-control" id="name" placeholder="" name="first_name" defaultValue={UserData.first_name} ref={register({
    required: 'Please enter name',
    minLength: {
      value: 2,
      message: "Name should contain atleast 2 characters"
    },
    maxLength: {
      value: 20,
      message: "Name should not exceed 20 characters"
    }  })}/>
    <p className="text-danger marginmessage">
    <ErrorMessage errors={errors} name="first_name">
      {({ message }) => <p className={"text-danger"}>{message}</p>}
    </ErrorMessage>
  </p>
</Col>
<Col lg ={6} md={6} sm={6}>
<label>Email address</label>
<input type="email"  className="form-control" id="email" placeholder="" name="email" defaultValue={UserData.email}
ref={register({ required: "Please enter email address",pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "invalid email address"
      } })}/>
<p className="text-danger marginmessage">
  <ErrorMessage errors={errors} name="email">
    {({ message }) => <p className={"text-danger"}>{message}</p>}
  </ErrorMessage></p>
</Col>

<Col lg ={6} md={6} sm={6}>
<label>Contact Number</label>
<input type="text" className="form-control" id="contact_number" placeholder="" name="contact_number" defaultValue={UserData.contact_number}
ref={register({
required: 'Please enter contact number' ,
minLength: {
  value: 7,
  message: "Contact Number should contain atleast 7 digits"
},
maxLength: {
  value: 15,
  message: "Contact Number should not exceed 15 digits"
}  })}/>
<p className="text-danger marginmessage">
<ErrorMessage errors={errors} name="contact_number">
    {({ message }) => <p className={"text-danger"}>{message}</p>}
  </ErrorMessage>
</p>

</Col>

<Col lg ={6} md={6} sm={6}>
<label>Mobile Number</label>
<input type="text" className="form-control" id="mobile" placeholder="" name="mobile"  defaultValue={UserData.phone} 
ref={register({
required: 'Please enter mobile number',
minLength: {
value: 7,
message: "Mobile Number should contain atleast 7 digits"
},
maxLength: {
value: 15,
message: "Mobile Number should not exceed 15 digits"
}  })}/>
<p className="text-danger marginmessage">
<ErrorMessage errors={errors} name="mobile">
 {({ message }) => <p className={"text-danger"}>{message}</p>}
</ErrorMessage></p>

</Col>






<Col lg={12}>

<div className="ml-auto text-right cancel_button mt-3">

<ul>
  <li>
  <a href="#">Cancel</a>
  </li>
  <li>
  <Button type="Submit" value="Save Section">Submit</Button>
  </li>
</ul>

</div>
</Col>

</Row>
</form>
</React.Fragment>
</div>

</div>

</div>

</div>


{/* dashbaord-inner */}





    </div> 


    <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>


    </div>
  );
}

export default MyProfile;
