import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Input, Button, Form, Label, } from "reactstrap";
import { ErrorMessage, useForm } from 'react-hook-form';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory } from "react-router-dom";
import { useAlert } from 'react-alert';
const Register = (props) => {
  // const { state } = props.location ? props.location : ''
  // const [linkedInData] = useState(JSON.parse(state.result ? state.result : ''));
  var linkedInDataTEM = false;
  const { state } = props.location ? props.location : false;
  
  if (state) {
    linkedInDataTEM = state.result
  }
  const [linkedInData] = useState(JSON.parse(linkedInDataTEM ? linkedInDataTEM : false));

  const session = useSession();
  const alert = useAlert();
  const { register, handleSubmit, errors, watch } = useForm({ mode: "onSubmit" });
  const [token] = useState(session.token);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [styler, setStyler] = useState(false);


  const onSubmit = async data => {
    console.log(">>>>>>>>>>>>>>>>>");
    setLoading(true);
    let formData = new FormData();
    if (data.password !== data.c_password) { errors.c_password = true; }
    var postJson = { company_name: data.company_name.trim(), registration_number: data.registration_number.trim(), first_name: data.first_name.trim(),  email: data.email, contact_number: data.contact_number.trim(), phone: data.mobile.trim(), password: data.password };
    if (data.socialId) {
      postJson.socialId = data.socialId;
      postJson.imageURL = data.imageURL;
    }
    formData.append('data', JSON.stringify(postJson));
    //formData.append('profile_pic', profilePic);
    let path = apiUrl.add_user;
    const fr = await Helper.formPost(formData, path);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {
        setLoading(false);
        alert.success(res.msg)
        props.history.push('/login');
        /*this.msg.show(res.msg, {
          time: 2000,
          type: 'success'
        })*/
      } else {
        console.log(res.msg);
        setLoading(false);
      }
    } else {

      console.log(res.error);
      setLoading(false);
    }
  };
  const contactPerson = (e) => {
    const re = /^[a-zA-Z0-9 ]*$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  //console.log('KKKKKKKKKKKK', linkedInData.profilePicture.displayImage + '~')
  // let lastElement = linkedInData[linkedInData.profilePicture.displayImage+'~'.length - 1];
  // console.log(lastElement);
  return (
    <>
      <section className="login_signup_sec py-4 py-md-5">
        <Container className="py-0 py-md-5">
          <Form className="login_signup_form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">

            <div className="d-flex align-items-center mobile_dflex mb-2 mb-md-4">
              <div className="form_logo text-center ">
                <img src="./assets/img/logo.png" alt="LaundryHUB" />
              </div>
              <div className="ml-auto login_text">
                <span>Let's start, it's easy</span>
              </div>
            </div>


            <Row>
              <Col lg={6} md={6} sm={6} className="p-1 p-md-2">

                <FormGroup>
                  {linkedInData && (
                    <>
                      <input type="hidden" className="form-control" name="socialId" defaultValue={linkedInData ? linkedInData.id : ''} ref={register()} />


                      <input type="hidden" className="form-control" name="imageURL" defaultValue={linkedInData ? linkedInData.profilePicture.displayImage : ''} ref={register()} />
                    </>
                  )

                  }
                  <label>  Company Name </label>
                  <input type="text" name="company_name" className="form-control" onKeyPress={contactPerson} placeholder="" ref={register({
                    required: 'Please enter company name',
                    minLength: {
                      value: 2,
                      message: "Company name should contain atleast 2 characters"
                    },
                    maxLength: {
                      value: 40,
                      message: "Company name should not exceed 20 characters"
                    }
                  })} />
                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="company_name">
                      {({ message }) => <p className={"text-danger"}>{message}</p>}
                    </ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              <Col lg={6} md={6} sm={6} className="p-1 p-md-2">
                <FormGroup>
                  <label> Company Registration Number </label>
                  <input type="text" name="registration_number" className="form-control" placeholder="" ref={register({
                    required: "Please enter company registration number",
                    minLength: {
                      value: 2,
                      message: "Company registration number should contain atleast 2 characters"
                    },
                    maxLength: {
                      value: 20,
                      message: "Company registration number should not exceed 20 characters"
                    }
                  })} />

                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="registration_number">
                      {({ message }) => <p className={"text-danger"}>{message}</p>}
                    </ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              <Col lg={6} md={6} sm={6} className="p-1 p-md-2">
                <FormGroup>
                  <label>  Name </label>
                  <input type="text" name="first_name" defaultValue={linkedInData ? linkedInData.firstName.localized.en_US + ' ' + linkedInData.lastName.localized.en_US : ''} onKeyPress={contactPerson} className="form-control" placeholder="" ref={register({
                    required: 'Please enter name',
                    minLength: {
                      value: 2,
                      message: "Name should contain atleast 2 characters"
                    },
                    maxLength: {
                      value: 20,
                      message: "Name should not exceed 20 characters"
                    }
                  })} />
                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="first_name">
                      {({ message }) => <p className={"text-danger"}>{message}</p>}
                    </ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              <Col lg={6} md={6} sm={6} className="p-1 p-md-2">
                <FormGroup>
                  <label> Email Address </label>
                  <input type="email" name="email" className="form-control" placeholder="" ref={register({
                    required: "Please enter email address", pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address"
                    }
                  })} />
                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="email">
                      {({ message }) => <p className={"text-danger"}>{message}</p>}
                    </ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              <Col lg={6} md={6} sm={6} className="p-1 p-md-2">
                <FormGroup>
                  <label> Contact Number </label>
                  <input type="text" name="contact_number" className="form-control" placeholder="" ref={register({
                    required: 'Please enter contact number',
                    minLength: {
                      value: 7,
                      message: "Contact Number should contain atleast 7 digits"
                    },
                    maxLength: {
                      value: 15,
                      message: "Contact Number should not exceed 15 digits"
                    }
                  })} />

                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="contact_number">
                      {({ message }) => <p className={"text-danger"}>{message}</p>}
                    </ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              

              

              <Col lg={6} md={6} sm={6} className="p-1 p-md-2">
                <FormGroup>
                  <label> Mobile Number </label>
                  <input type="text" name="mobile" className="form-control" placeholder="" ref={register({
                    required: 'Please enter mobile number',
                    minLength: {
                      value: 7,
                      message: "Mobile Number should contain atleast 7 digits"
                    },
                    maxLength: {
                      value: 15,
                      message: "Mobile Number should not exceed 15 digits"
                    }
                  })} />
                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="mobile">
                      {({ message }) => <p className={"text-danger"}>{message}</p>}
                    </ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              <Col lg={12} className="p-1 p-md-2">
                <FormGroup>
                  <label> Password </label>
                  <input type="password" name="password" className="form-control" placeholder="" ref={register({
                    required: 'Please enter password ',
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters"
                    },
                    maxLength: {
                      value: 15,
                      message: "Password should not exceed 15 digits"
                    }
                  })} />
                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="password">
                      {({ message }) => <p className={"text-danger"}>{message}</p>}
                    </ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              <Col lg={12} className="p-1 p-md-2">
                <FormGroup>
                  <label> Confirm Password </label>
                  <input type="password" name="c_password" className="form-control" placeholder="" ref={register({ validate: (value) => value === watch('password') || "Passwords don't match" })} />
                  <p className="text-danger marginmessage">
                    <ErrorMessage errors={errors} name="c_password" className="text-danger marginmessage"> {({ message }) => <p className="text-danger marginmessage">{message}</p>}</ErrorMessage>
                  </p>
                </FormGroup>
              </Col>

              <Col lg={12} className="p-1 p-md-2">
                <FormGroup>
                  <div className="term_condition_checkbox">
                    <input id="myCheckbox2" type="checkbox" name="accept_tc" ref={register({ required: true })} />
                    <label for="myCheckbox2">I accept the <a href="/terms-condition" className="same_color_term">Terms of Service</a></label>
                    <span></span>
                  </div>
                  {errors && errors.accept_tc && <p className="text-danger marginmessage">Please check accept terms & condition</p>}
                </FormGroup>
              </Col>

              <Col lg={12} className="p-1 p-md-2 mt-2">
                <FormGroup>
                  <div>
                    <button type="submit" className="form-control btn"  >REGISTER</button>
                  </div>
                </FormGroup>
              </Col>

              <Col lg={12} className="p-1 p-md-2 mt-2">
                <FormGroup>
                  <div className="already_account text-center">
                    <p>Already have an account? <a href={`/login`} className="same_color_term">Login</a></p>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Container>

      </section>
    </>
  );
}
export default Register;
