import React, { useState, useEffect } from "react";
import ProfileHeader from "./Action/ProfileHeader";
import ProfileSidebar from "./Action/ProfileSidebar";
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from 'react-alert';
import moment from "moment";
import Pagination from "react-js-pagination";
const History = (props) => {
  const [UserData, setData] = useState({});
  const session = useSession();
  const [token] = useState(session.token);
  const location = useLocation();
  const alert = useAlert();
  const [id, setId] = useState('');
  const checkAuthToken = localStorage.getItem("usertoken");
  const [visible, setVisibale] = useState(false);
  const [activepage] = useState(1);
  const [superdata, setSuperdata] = useState([]);
  const [totalitems, setTotalItems] = useState('');
  const [imgurl, setimageurl] = useState('');
  const [keywords, setKeyWords] = useState('');
  const [isserach, setIsserach] = useState(false);
  let history = useHistory();
  if (checkAuthToken == null) {
    history.push("/login");
  }
  function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  const handleSearching = async () => {
    setIsserach(true);
    const user_Id = localStorage.getItem('userid');
    const itemsPerPage = 10;
    let path = apiUrl.getUserEnquiry + '?id=' + user_Id + '&page=' + `${activepage}` + '&itemsPerPage=' + `${itemsPerPage}` + '&keyword=' + `${keywords}`;
    console.log(path);
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {
        setSuperdata(res.results.docs || []);
        setTotalItems(res.results.totalDocs);
        setIsserach(false);
      } else {
        alert.error(res.msg)
        setIsserach(false);
      }
    } else {
      alert.error(res.error)
      setIsserach(false);
    }

  };


  function SidebarRemoveClass() {
    console.log("tset")
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }
  const user_Id = localStorage.getItem('userid');
  const getUserData = async () => {

    let path = apiUrl.get_user + '/' + `${user_Id}`;
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {

        setId(res.results.id);
        setData(res.results);
        console.log(res.results.image)

      } else {
        console.log(res.msg);
      }
    } else {
      console.log(res.msg);
    }

  };
  const getData = async (page = activepage) => {
    setVisibale(true);
    const itemsPerPage = 10;
    const user_Id = localStorage.getItem('userid');

    let path = apiUrl.getUserEnquiry + '?id=' + user_Id + '&page=' + `${page}` + '&itemsPerPage=' + `${itemsPerPage}`;
    console.log(path);
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    console.log(res.results);
    if (fr.status === 200) {
      if (res.success) {
        console.log(res.results.docs);
        setSuperdata(res.results.docs || []);
        setTotalItems(res.results.totalDocs);
        setVisibale(false)
        setimageurl(res.imgurl);
      } else {
        alert.error(res.msg)
        setVisibale(false)
      }
    } else {
      alert.error(res.error)
      setVisibale(false)
    }

  };
  useEffect(() => {
    getUserData()
    getData()
  }, []);
  return (

    <div>

      <ProfileHeader item={UserData} />

      <div className="dashboard_outer">
        <ProfileSidebar item={UserData} />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <a href="javascript:void(0);" onClick={SidebarAddClass} className="toggle_btn d-lg-none"><i className="fas fa-bars"></i></a>
          </div>


          <div className="dash_main_inner">

            <div className="row align-items-center">
              <div className="col-sm-5">
                <h2 className="dash_heading pl-3 pl-md-0">History of Quotes</h2>
              </div>

              <div className="ml-auto  justify-content-end  d-flex filter_div col-sm-7" >

                <div className="Search_sec p-0 border-0 bg-white">
                  <div class="Search_field">
                    <input type="text" name="" class="form-control" value={keywords} placeholder="Search" onChange={(e) => { setKeyWords(e.target.value) }} />
                    <button class="btn_search btn" onClick={handleSearching}><i class="fas fa-search"></i></button>
                  </div>
                </div>

                <div className=" pl-3">
                  {/*<button className="btn_filter"><i class="fa fa-filter mr-2" aria-hidden="true"></i>Filter</button>*/}
                </div>
              </div>
            </div>


            {/* notify */}
            {(() => {
              if (superdata.length == 0) {
                return (
                  <label className="noRecord">No Record Found !</label>
                )
              }
            })()}
            {superdata.map((item, key) => {
              return (
                <div className="discussion_notify d-flex " key={item.id}>
                  <div className="alternate_image history-image">

                    {item.product_id && <img src={`${imgurl}${item.product_id.primary_image}`} />}


                  </div>

                  <div className="lerory px-2 p-xl-4 pt-sm-3">
                    {item.product_id && <h2>{item.product_id.product_name}</h2>}
                    <p className="m-0 p-0"> <span> Enquiry For:  </span>{item.enquiry_type}</p>
                    <p className="m-0 p-0"> <span> Comment:  </span>{item.comment}</p>
                    <p className="m-0 p-0"> <span>Date & Time:  </span>{moment(item.createdAt).format('LLL')}</p>
                    {item.product_id && <div className="price">${item.product_id.price}</div>}
                  </div>

                  {/* <div className="ml-sm-auto box_discount">
                    <a href="#" className="btn_discount">{item.status}</a>
                  </div> */}
                </div>

              )


            })}
            <div className="pagination_deshbaord d-flex justify-content-center justify-content-md-end">
              {superdata !== 0 && <div className="show-pagination technician-page">
                <Pagination
                  activeClass={""}
                  activeLinkClass={"page-link active"}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activePage={activepage}
                  itemsCountPerPage={10}
                  totalItemsCount={totalitems}
                  pageRangeDisplayed={4}
                  prevPageText="Previous"
                  nextPageText="Next"
                  firstPageText="<"
                  lastPageText=">"
                  onChange={getData}
                />
              </div>}


            </div>





            {/* notify */}


          </div>






        </div>



      </div>





      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>


    </div>
  );
}
export default History;
