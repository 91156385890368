import React, { useState, useEffect } from "react";
import {Container, Row,Col,FormGroup,Input,Label,Form } from "reactstrap";
import { useForm } from 'react-hook-form';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useHistory,Redirect } from "react-router-dom";
import { Button,Modal,Nav,Tabs,Tab}  from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from 'react-alert';
const Enquiry = (props) => { 
  const [show, setShow] = useState(false);
  const session = useSession();
  const alert = useAlert();
  const [token] = useState(session.token);
  const { register, handleSubmit, formState: { errors }, watch } = useForm({ mode: "onSubmit"});
  const checkAuthId = localStorage.getItem("userid");
  
  const [SetProductTypeValue, SetProductType] = useState('');
  const [images, setImages] = useState([]);
  const [imgurl, setImageUrl] = useState('');
  const [ProductData, setData] = useState({});
  const [Units, setUnit] = useState('');
  const [editprimaryPreview, setPrimaryImage] = useState('');
  const [id, setId] = useState('');
  const [pname, SetPname] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [visible, setVisibale] = useState(false);
  

  const getProductData = async () => {
      
    let path = apiUrl.get_product + '/' + `${props.match.params.id}`;
   
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
        if (res.success) {
            setId(res.results.id);
            setUnit(props.location.state.units)
            SetProductType(res.results.product_type);
            SetPname(res.results.product_name);
            setPrimaryImage(res.results.primary_image)
            setImageUrl(res.imgurl);
           
            var primary={};
            primary['original']=res.imgurl+res.results.primary_image;
           
            setData(res.results);
        } else {
            console.log(res.msg);
        }
    } else {
        console.log(res.msg);
    }
};
const changeUnit=async(unit)=>{
 
  setUnit(unit)
}
const onSubmit = async (data) => {
    var user_id=localStorage.getItem('userid')
  setVisibale(true);
  let postJson = '';
  if(props.location.state.type=='Rent'){
    postJson={product_name:pname,units:Units,user_type: 'user',product_id:id,user_id:user_id,start_date:props.location.state.start_date,
    end_date:props.location.state.end_date,enquiry_type:'Rent'};
  }
  else{
    postJson={product_name:pname,units:Units,user_type: 'user',product_id:id,user_id:user_id,enquiry_type:'Buy'};

  }
  console.log(postJson);
  let path = apiUrl.sendEnquiry;
  const fr = await Helper.post(postJson, path);
  const response_data = await fr.response.json();
  
  if (fr.status === 200) {
    if (response_data.success) {
      alert.success(response_data.msg);
      setVisibale(true);
      props.history.push({
        pathname:"/detail-product/"+ProductData._id,
      });
    } else {
      alert.error(response_data.msg);
      setVisibale(true);
    }
  } else {
    setVisibale(true);
    alert.error(response_data.error);
   
  }
};

useEffect(() => {
 
  getProductData();
 
}, []);

return (
  <>

   <section className="enquiry_form">
   <Container>
     <Row>
       <Col lg={6} md={8} className="m-auto d-block">
         <div className="enquiry_form_inner">
         
           <h2 className="mb-3">Enquiry Form</h2>
           <img src={`${imgurl}${ProductData.primary_image}`} alt="enquiry" className="img-fluid w-100"/>
           <div className="mt-2">
           <h4>{ProductData.product_name}</h4>
           
           {(() => {
              if (ProductData.show_on_front==1 && ProductData.price!=null) {
                return (
                    <h5>Price: ${ProductData.price}</h5>
                    )
                  }
                })()}
           <span>
           Qty1
           </span>
         
        
        <Form className="" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
            <input type="text" name="units" defaultValue={Units} onKeyUp={(e) => {changeUnit(e.target.value)}} className="form-control"  placeholder="" {...register('units', { })}/>
            </FormGroup>
            <FormGroup>
                <input type="hidden" name="productid" defaultValue={id} className="form-control"  placeholder="" {...register('productid', { })}/>
            </FormGroup>
            <FormGroup>
                <input type="hidden" name="userid" defaultValue={checkAuthId} className="form-control"  placeholder="" {...register('userid', { })}/>
            </FormGroup>  
            <p className="mt-2">{ProductData.description}</p>
             <input type="submit" className="form-control btn" value="Submit"/>
          </Form>
                 
           </div>
         </div>
       </Col>
     </Row>
   </Container>

   </section>

  </>
  

 );
}
export default Enquiry;
