import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Input, Button, Label, Form } from "reactstrap";
import { ErrorMessage, useForm } from 'react-hook-form';

import { useAlert } from 'react-alert';
import Helper from '../../constants/helper';
import parse from 'html-react-parser';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useLocation, useHistory, Redirect, Link, useParams } from "react-router-dom";
const ContactUs = (props) => {
  const alert = useAlert();
  let history = useHistory();
  const [visible, setVisibale] = useState(false);
  const location = useLocation();
  const { register, handleSubmit, errors, watch } = useForm({ mode: "onSubmit" });



  const [staticDataAboutCompany, setstaticDataAboutCompany] = useState('')
  const getDataAboutCompany = async () => {
    try {
      let path = apiUrl.getStaticPage + 'about-company';
      const fr = await Helper.get(path);
      const res = await fr.response.json();
      setstaticDataAboutCompany(res.result.content)
    } catch (error) {
      console.log('error', error)
    }
  }

  const [staticDataOfficeAddress, setstaticDataOfficeAddress] = useState('')
  const getDataOfficeAddress = async () => {
    try {
      let path = apiUrl.getStaticPage + 'office-address';
      const fr = await Helper.get(path);
      const res = await fr.response.json();
      setstaticDataOfficeAddress(res.result.content)
    } catch (error) {
      console.log('error', error)
    }
  }


  useEffect(() => {
    getDataAboutCompany()
    getDataOfficeAddress()
  }, [])


  const prepareHtml = (description) => {
    if (description) {
      console.log('description', description)
      return { __html: description || "" };
    }

    return { __html: "" };
  };



  const onSubmit = async (data, e) => {
    setVisibale(true);
    // const params = new URLSearchParams(location.search);
    // var usertoken = props.match.params.token;
    let postJson = { name: data.name, email: data.email, message: data.message };
    let path = apiUrl.contactEnquiry;
    const fr = await Helper.post(postJson, path);
    const response_data = await fr.response.json();

    if (fr.status === 200) {
      if (response_data.success) {
        alert.success(response_data.msg);
        setVisibale(false);
        e.target.reset();
        //props.history.push('/login');
      } else {
        alert.error(response_data.msg);
        setVisibale(false);
      }
    } else {
      alert.error(response_data.error);
      setVisibale(false);
    }
  };


  return (
    <>
      <section id="faq" class="faq space_top">
        <Container>
          <div className="contact_heading text-center">
            <h2>Contact Us</h2>
          </div>
          <Row className="row space_top">
           
            <Col lg={5} md={5} sm={12} className="mt-md-5 mb-3">
              <div className="contact_info">

              {parse(staticDataAboutCompany)}
              
              {parse(staticDataOfficeAddress)}

              </div>
              
            
            </Col>

            <Col lg={7} md={7} sm={12} className="mt-md-5" >
              <div>
                <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
                  <h4>Enquiries</h4>
                  <span>For any enquiries, please call: <a href="#">+(65) 6542 5288</a> or Please fill out the following form below</span>
                  <div className="form-group">
                    <input class="form-control" type="text" name="name" placeholder="Your Name*" ref={register({
                      required: 'Please enter name ',
                      minLength: {
                        value: 2,
                        message: "Name should contain atleast 2 characters"
                      },
                      maxLength: {
                        value: 20,
                        message: "Name should not exceed 20 characters"
                      }
                    })} />
                    <p className="text-danger marginmessage">
                      <ErrorMessage errors={errors} name="name">
                        {({ message }) => <p className={"text-danger"}>{message}</p>}
                      </ErrorMessage></p>
                  </div>
                  <div className="form-group">
                    <input class="form-control" type="email" name="email" placeholder="Your Mail*"
                      ref={register({
                        required: 'Please enter email ',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address"
                        }
                      })} />
                    <p className="text-danger marginmessage">
                      <ErrorMessage errors={errors} name="email">
                        {({ message }) => <p className={"text-danger"}>{message}</p>}
                      </ErrorMessage></p>
                  </div>
                  <div className="form-group">
                    <textarea class="form-control" name="message" placeholder="Your Message*"
                      ref={register({
                        required: 'Please enter message ',
                        minLength: {
                          value: 2,
                          message: "Message should contain atleast 2 characters"
                        },
                        maxLength: {
                          value: 150,
                          message: "Message should not exceed 150 characters"
                        }
                      })} />
                    <p className="text-danger marginmessage">
                      <ErrorMessage errors={errors} name="message">
                        {({ message }) => <p className={"text-danger"}>{message}</p>}
                      </ErrorMessage></p>
                  </div>
                  <Button type="submit" className="btn btn_submit">Submit</Button>
                </form>
              </div>

            </Col>
          </Row>
        </Container>
      </section>

    </>
  );
}
export default ContactUs;
