import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ScrollToTop from "./layout/ScrollToTop";
import Loading from "../Component/Loader";
import Page from "../Pages";

import "bootstrap/dist/css/bootstrap.min.css";
import "./../assets/css/app.css";
import "./../assets/css/media.css";
import "@fortawesome/fontawesome-free/css/all.css";



const App = (props) => {
  const { isFetching, isAuth } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  var redirectTo = useSelector((state) => state.redirectTo);

  useEffect(() => {
    if (redirectTo.redirectTo) {
      dispatch({
        type: "REDIRECT",
        payload: false,
      });
      history.push(redirectTo.redirectTo);
    }
  });
  return (
    <>
      {isFetching && <Loading />}
      <ScrollToTop />
      <Page isAuth={isAuth} />
    </>
  );
};

function mapStateToProps(state) {
  return {
    isFetching: state.isFetching,
    isAuth: state.isAuth,
  };
}

export default connect(mapStateToProps)(App);
