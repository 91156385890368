import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import { PersistGate } from "redux-persist/integration/react";
import storeConfig from "./store/index";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import Zendesk from "react-zendesk";

import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import Login from "./Pages/Login";



const { store, persistor } = storeConfig;
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}
const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={config.basename}>
        <Switch >
          <Route exact path="/login" component={LinkedInPopUp} />
          {/* <Route path="/" component={Login} /> */}
        </Switch>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>

      </BrowserRouter>
    </PersistGate>
  </Provider>

);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
