import { useState, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Helper from '../../constants/helper';
import parse from 'html-react-parser';
import apiUrl from '../../constants/apiPath';
import Zendesk from "react-zendesk";

const ZENDESK_KEY = "643f04df-069d-45be-b97c-73cff12c8322";
const setting = {
   color: {
      theme: "#000"
   },
   launcher: {
      chatLabel: {
         "en-US": "Need Help"
      }
   },
   contactForm: {
      fields: [
         { id: "description", prefill: { "*": "My pre-filled description" } }
      ]
   }
};
const Footer = () => {

   const [staticDataFootertext, setstaticDataFootertext] = useState('')
  const getDataFootertext = async () => {
    try {
      let path = apiUrl.getStaticPage + 'footer-text';
      const fr = await Helper.get(path);
      const res = await fr.response.json();
      setstaticDataFootertext(res.result.content)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getDataFootertext()
  }, [])

   return (
      <>
         <footer>
            <Container>
               <Row>
                  <Col lg="7" md="5" className="pr-md-5">
                     <div className="footer-content">
                        <img src="../assets/img/footer_logo.png" className="img-fluid" />
                        { /*<p>It is a long established fact that a reader will be distracted by the readable content of a
                           looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                           distribution of letters, as opposed to usingR.
                        </p> */ }
                        <p> {parse(staticDataFootertext)} </p>

                     </div>
                  </Col>
                  <Col lg="2" md="3" sm="6" className="pl-lg-5">
                     <div className="quikc-heading">
                        <div className="link-heading w-100 ">Sort Links</div>
                        <ul>


                           <li><a href={`/about-us`}>About Us</a></li>
                           <li><a href={`/contact-us`}>Contact Us</a></li>
                           <li><a href={`/faq`}>FAQ’s</a></li>
                           

                        </ul>
                     </div>
                  </Col>
                  <Col lg="3" md="4" sm="6" >
                     <div className="social-footer-div">
                        <div className="link-heading">Connect with us</div>
                        <ul className="social-footer">
                           {/* <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li> */}
                           <li><a href="https://www.linkedin.com/company/artco-associated-rental-tools-company/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                           {/* <li><a href="#"><i class="fab fa-youtube"></i></a></li> */}
                        </ul>
                     </div>
                  </Col>
               </Row>
            </Container>
            <div className="copyright text-center">
               <Container>
                  <p>© Copyright {new Date().getFullYear()} - ARTCO. All Rights Reserved.</p>
               </Container>
            </div>
            <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} alwaysShow onLoaded={() => console.log('is loaded')} />
         </footer>
      </>
   );
};

export default Footer;
