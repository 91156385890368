import React, { useState, useEffect } from 'react';
import Helper from '../../../constants/helper';
import apiUrl from '../../../constants/apiPath';
import { useAlert } from 'react-alert';
import useSession from 'react-session-hook';
import {Dropdown } from 'react-bootstrap';
import {Col, Container, Row,Form,FormGroup } from "reactstrap";
import { useHistory,useLocation } from "react-router-dom";
const ProfileSidebar = (props) => {
  const session = useSession();
  const token = session.token;
  const alert = useAlert();  
  const [item, setItem] = useState({});
  const [notif, setnotifyCount] = useState('');
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const Logout = () => {
    localStorage.clear("usertoken");
    localStorage.clear("userid");
    alert.success("LOGOUT SUCCESSFULLY");
    //this.props.history.push('/login');
   };
   const noticount=async()=>{
    setnotifyCount(0)
    const user_Id = localStorage.getItem('userid');
    let postJson = {user_Id:user_Id}
    let path = apiUrl.setNotificationRead;
    const fr = await Helper.post(postJson, path, token);
    
    history.push('notification')
   }
   const getNotificationData = async (page = 1) => {
    
    const itemsPerPage = 10;
    const user_Id = localStorage.getItem('userid');
    
    let path = apiUrl.getNotification + '?id=' +user_Id +'&page=' + `${page}` + '&itemsPerPage=' + `${itemsPerPage}`;
    console.log(path);
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    console.log(res.results);
    if (fr.status === 200) {
      if (res.success) {
        console.log(res.results.docs);
        setnotifyCount(res.notiCount);
        
      } else {
       alert.error(res.msg)
       
      }
    } else {
      alert.error(res.error)
     
    }

  };
   const onImageChange= async event => { 
  
    if (event.target.files && event.target.files[0]) {
    
      let formData = new FormData();
      let postJson = { id: localStorage.getItem('userid')}
      formData.append('data', JSON.stringify(postJson));
      formData.append('profile_pic', event.target.files[0]);
      let path = apiUrl.update_profile_pic;
      
          const fr = await Helper.formPost(formData, path, token);
          const res = await fr.response.json();
          if (fr.status === 200) {
              if (res.success) {
                  setLoading(true);
                  //props.history.push('/users');
                  alert.success(res.msg);
                  window.location.reload();
              } else {
                  alert.error(res.msg);
                  setLoading(false);
              }
          } else {
              alert.error(res.error);
              setLoading(false);
          }
    }
  }
  const current_path=location.pathname;
  useEffect(() => {
    setItem(props.item);
    getNotificationData();
  }, [props.item]);

  return (
    <div className="dashboard_sidebar">
    <div className="dashboard_profile text-center mb-3 mb-md-4 mb-lg-5">
      <div className="profile_userimg">
       <figure>
         
            <img src={item.image} />
      </figure>
      <span>
      <i class="fa fa-camera" aria-hidden="true"></i>
      <input type="file" className="custom-file n" name="profile_pic" onChange={onImageChange}/> 
      </span>

      </div>
      <h5>{item.first_name}</h5>
     </div> 

     <div className="dashboard_menu">
          <ul>
              <li className={current_path === "/dashboard" ? "active" : ""}><a href="/dashboard"><span>
              <img src="../assets/img/dashboard.png" /> 
              <img src="../assets/img/dashboard-h.png" className="hover" /></span>Dashboard</a></li>
              <li className={current_path === "/my-profile" ? "active" : ""}><a href="/my-profile">
                <span><img src="../assets/img/profile.png" />
                 <img src="../assets/img/profile-h.png" className="hover" />
                </span>My Profile</a></li>
              <li><a href="/history"><span><img src="../assets/img/history.png" />
              <img src="../assets/img/history-h.png" className="hover" />
              </span>History of Quotes</a></li>
              <li onClick={noticount}><a href="#"><p class="notify-count">{notif}</p><span><img src="../assets/img/notification.png" />
              <img src="../assets/img/notification-h.png" className="hover" />
              
              </span>Notifications</a></li>
              <li><a href="/change-password"><span><img src="../assets/img/lock.png" />
              <img src="../assets/img/lock-h.png" className="hover" />
              </span>Change Password</a></li>
              <li onClick={Logout}><a href="#"><span><img src="../assets/img/logout.png" />
              <img src="../assets/img/logout-h.png" className="hover" />
              </span>Logout</a></li>
          </ul>
      </div> 

</div>


  );
}

export default ProfileSidebar;
