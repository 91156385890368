import React, { useState, useEffect } from "react";
import {Container, Row,Col,FormGroup,Input, Form,Label, } from "reactstrap";
import { useForm } from 'react-hook-form';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useLocation, useHistory } from "react-router-dom";
import { Button, Card, Accordion } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import Status from './Action/Status';
const queryString = require("query-string");
const Sidebar = (props) => { 
   const session = useSession();
   const { register, handleSubmit, formState: { errors }, watch } = useForm({ mode: "onSubmit"});
   const [query, setQuery] = useState({});
   const alert = useAlert();
   let history = useHistory();
   const [token] = useState(session.token);
   const location = useLocation();
   const [selectCategory, setCategory] = useState([]);
   const [selectSubCategory, setSubCategory] = useState([]);
   const url = require('url');
   const querystring = require('querystring');
   const getCategory = async () => {
    let path = apiUrl.get_category_value;
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {
        
        setCategory(res.results.docs || []);
      
      } else {
        alert.error(res.msg);
        
      }
    } else {
      alert.error(res.error);
    }
  }
  let subcat_id="";
  const params = new URLSearchParams(location.search);
  const [active, setActive] = useState(params.get("categoryId"));
  const changeCategory = async (id) => {
   
    let path = apiUrl.get_category_subcategory + '/' + `${id}`;
    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {
        
       setSubCategory(res.results|| []);
      } else {
        alert.error(res.msg);
      }
    } else {
      
      alert.error(res.error);
    }
  
 };
 const typearray=[
  {key:"Rent",value:"Rent"},
  {key:"Sale",value:"Sale"},
  {key:"Both",value:"Both"},
 ]
 const conditionarray=[
  {ckey:"New",cvalue:"New"},
  {ckey:"Refurbished",cvalue:"Refurbished"},
  {ckey:"Old",cvalue:"Used"},
  {ckey:"Unused",cvalue:"Unused"}
 ]
 

const handleSetFilter = (type, id) => {
  let queryStringParsed = queryString.parse(location.search);
  if (
    queryStringParsed[type] &&
    queryStringParsed[type].includes(id.toString())
  ) {
    return true;
  } else {
    return false;
  }
};


  useEffect(() => {
    getCategory();
   
  }, []);
   
   return (
   

 <div id="left_sidebar"  className="sidebar">

 

 <div className="sidebar_heading seprator">
   <h3 className="d-flex align-items-center">
     Filters <a href={`\listing`} className="link ml-auto">Reset Filters</a>
   </h3>
 </div>
 <div className="sidebar_toggle">
 

 <div className="seprator">
     <h6>Category</h6>





<Accordion defaultActiveKey="0">
{selectCategory.map((item, index) => {
   return(
  <Card key={index} className="border-0">
    <Card.Header className="p-0 border-0 bg-white card-header">
      <Accordion.Toggle as={Button} className="w-100 p-2 bg-white border-0 text-left btn btn-link" variant="link" eventKey={item.id} onClick={() => {props.onClick(item); changeCategory(item.id);}}>
        {item.category_name}
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey={item.id} class="show">
      <Card.Body className="border-0 py-0">
      <ul className="sidebar_menu pl-3">
                                  {selectSubCategory.map((headers) => {
                              
                                   return  headers.parent_id==item.id ?
                                    <li  className={active === headers.id ? "catactive" : ""}
                                    onClick={() => setActive(headers.id)}
                                    key={headers.id}
                                    >
                                    <a href="#"
                                        className="categoryData"
                                        onClick={() => props.onClick(headers)}
                                      >
                                        {headers.category_name}
                                        
                                    </a>
                                    </li>
                                    :
                                    <li>
                                    <p></p> </li>
                                   
                                  })}
                                  </ul>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
      );
                            })}
</Accordion>









     </div>


    {/* <div className="seprator">
     <h6>Location</h6>
      <div className="Search_sec p-0 border-0 bg-white">
  <input type="text" name="" className="form-control" placeholder="Type Location" />
</div>
                          </div>*/}

   <div className="seprator">
     <h6>Types</h6>
     <ul>
     {typearray.map((discountItem, index) => (
                  <li key={index}>
                    <span className="custom_check">
                      {discountItem.value} &nbsp;
                      <input
                        type="checkbox"
                        onClick={() => props.onClick(discountItem)}
                        defaultChecked={handleSetFilter(
                          "type",
                          discountItem.key
                        )}
                      />
                      <span className="check_indicator">&nbsp;</span>
                    </span>
                  </li>
                ))}
      </ul>
   </div>
   <div className="seprator">
     <h6>Condition</h6>
     <ul>
     {conditionarray.map((condItem, index) => (
                  <li key={index}>
                    <span className="custom_check">
                      {condItem.cvalue} &nbsp;
                      <input
                        type="checkbox"
                        onClick={() => props.onClick(condItem)}
                        defaultChecked={handleSetFilter(
                          "condition",
                          condItem.ckey
                        )}
                      />
                      <span className="check_indicator">&nbsp;</span>
                    </span>
                  </li>
                ))}

     </ul>
   </div>
 </div>
</div>


);
}
export default Sidebar;
