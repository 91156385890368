import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import Slider from "react-slick";
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import { useAlert } from 'react-alert';
import { useLocation, useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useSession from 'react-session-hook';
const HomeSlider = (props) => {
  const alert = useAlert();
  const { settings, number } = props;
  const session = useSession();
  const location = useLocation();
  const [products, setProduct] = useState([]);
  const [isserach, setIsserach] = useState(false);
  const [visible, setVisibale] = useState(false);
  const [imgurl, setImageUrl] = useState('');
  const [token] = useState(session.token);
  const [query, setQuery] = useState({});
  const [bannerPath, setBannerPath] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const MAX_LENGTH = 30;
  const [categoryPath, setCategoryPath] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [topPath, setTopPath] = useState(false);
  const [topProductData, setTopProductData] = useState([]);
  const getCategoryData = async () => {
    let path;
    path = apiUrl.get_category;
    const fr = await Helper.get(path);
    const res = await fr.response.json();

    if (fr.status === 200) {
      if (res.success) {
        setCategoryPath(res.imgUrl);
        setCategoryData(res.results || []);
      } else {
        // alert.error(res.msg);

      }
    } else {
      //alert.error(res.error);
    }
  }
  const listingurl = '/listing';
  const getTopProductsData = async () => {
    let path;
    path = apiUrl.get_top_product;
    const fr = await Helper.get(path);
    const res = await fr.response.json();

    if (fr.status === 200) {
      if (res.success) {
        setTopPath(res.imgUrl);
        console.log("Res =>", res.results);
        setTopProductData(res.results || []);

      } else {
        // alert.error(res.msg);

      }
    } else {
      //alert.error(res.error);
    }
  }
  const getData = async () => {
    let path;
    path = apiUrl.get_banners;
    const fr = await Helper.get(path);
    const res = await fr.response.json();

    if (fr.status === 200) {
      if (res.success) {

        setBannerPath(res.imageUrl);
        setBannerData(res.results || []);
      } else {
        //alert.error(res.msg);

      }
    } else {
      //alert.error(res.error);
    }
  }
  const getProductData = async () => {
    query["itemsPerPage"] = 8
    let queryString = Helper.serialize(query);
    let path = apiUrl.product_listing + `?${queryString}`;

    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {

        setProduct(res.results.docs || []);

        setIsserach(false);
        setVisibale(false);
        setImageUrl(res.imgurl);
      } else {
        //alert.error(res.msg);
        setIsserach(false); setVisibale(false);
      }
    } else {
      //alert.error(res.error);
      setIsserach(false); setVisibale(false);
    }
  }

  useEffect(() => {
    getProductData()
    getData();
    getCategoryData();
    getTopProductsData();
  }, []);
  return (
    <>
      {number === 11 &&

        <Slider {...settings}>
          {bannerData.map((item, key) => {
            return (
              <div>
                <img key={key} src={bannerPath + item.banner_image} alt="" />
              </div>)
          })}

        </Slider>
      }

      {number === 1 && <Slider {...settings}>
        {categoryData.map((item, key) => {
          return (
            <a href={`${listingurl}${'?parent_id=0&categoryId='}${item.id}`}><div className="service_icon ">
              <img key={key} src={categoryPath + item.category_icon} className="img-fluid" />
              <h2 className="mt-3">{item.category_name}</h2>
            </div></a>)
        })}

      </Slider>
      }

      {number === 2 &&
        <Slider {...settings}>
          {topProductData.map((item, key) => {
            return (
              <div className="product_content top-product-slider" key={item.id}>
                <div className="product_inner_content">
                  <div>
                    <img src={`${imgurl}${item.primary_image}`} className="img-fluid" />
                    {(() => {
                      if (item.marksold == 1) {
                        return (
                          <div className="sold_tag">Sold</div>
                        )
                      }
                    })()}
                  </div>
                  <div className="product_content_cn">
                    <h3>{item.product_name}</h3>
                    {item.description.length > MAX_LENGTH ?
                      (
                        <p> {`${item.description.substring(0, MAX_LENGTH)}...`}</p>
                      ) :
                      <p>{item.description}</p>
                    }
                    <a href={`/detail-product/${item.id}`} className="btn-change7">Enquire Now</a>
                  </div>
                </div>
              </div>)
          })}
        </Slider>
      }

      {number === 3 &&
        <Slider {...settings}>
          {/* <div>
            <img src="/assets/img/partner_logo1.png" className="img-fluid" />
          </div>
          <div>
            <img src="/assets/img/partner_logo2.png" className="img-fluid" />
          </div>
          <div>
            <img src="/assets/img/partner_logo3.png" className="img-fluid" />
          </div>
          <div>
            <img src="/assets/img/partner_logo4.png" className="img-fluid" />
          </div>
          <div>
            <img src="/assets/img/partner_logo5.png" className="img-fluid" />
          </div>
          <div>
            <img src="/assets/img/partner_logo6.png" className="img-fluid" />
          </div> */}
        </Slider>
      }

      {number === 4 &&
        <Slider {...settings}>
          {products.map((item, key) => {
            return (
              <div className="product_content">
                <div className="product_inner_content">
                  <div>
                    <img src={`${imgurl}${item.primary_image}`} className="img-fluid" />

                  </div>
                  <div className="product_content_cn">
                    <h3>{item.product_name}</h3>
                    {item.description.length > MAX_LENGTH ?
                      (
                        <p> {`${item.description.substring(0, MAX_LENGTH)}...`}</p>
                      ) :
                      <p>{item.description}</p>
                    }
                    <a href={`/detail-product/${item.id}`} className="btn-change7">Enquire Now</a>
                  </div>
                </div>
              </div>);
          })}




        </Slider>
      }
    </>
  );
}
export default HomeSlider;
