import React, { Component, useContext, useState, useEffect } from "react";
import useSession from "react-session-hook";
import { useHistory, Redirect } from "react-router-dom";
import { useAlert } from 'react-alert';
import Helper from '../../constants/helper';
import apiUrl from '../../constants/apiPath';
import {
  Container,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, NavbarBrand, NavbarText, toggle
} from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

window.addEventListener('resize', function () {
  var w = window.innerWidth,
    name = document.getElementsByName('name1')[0];
  if (w <= 400) {
    if (name != undefined) {
      name.placeholder = 'search by keywords....';
    }
  } else {
    if (name != undefined) {
      name.placeholder = "Search by keywords";
    }
  }
});


const Header = (props) => {
  const session = useSession();
  let history = useHistory();
  const alert = useAlert();
  const [redirectUrl, setRedirctUrl] = useState('');
  const [token] = useState(session.token);
  const [abc, setItems] = useState([]);
  const [select_id, setselectid] = useState('');
  const checkAuthToken = localStorage.getItem("usertoken");
  const [isOpen, setIsOpen] = useState('');

  const toggle = () => {
    let newClass = isOpen == 'show' ? null : "show"
    setIsOpen(newClass);
    // this.setState((prevState) => ({
    //   isOpen: !prevState.isOpen,
    // }));
    //setIsOpen(true);
  };
  const Logout = () => {
    localStorage.clear("usertoken");
    localStorage.clear("userid");
    alert.success("User Loggged Out");
    return <Redirect to='/login' />
  };


  const getProductData = async () => {

    let path = apiUrl.get_products_name;

    const fr = await Helper.get(path, token);
    const res = await fr.response.json();
    if (fr.status === 200) {
      if (res.success) {
        setItems(res.results);
        /*for(var i=0;i<res.results.length;i++){
          let arrayimages={};
          arrayimages['id']="1"
          arrayimages['name']="test"
         
          abc.push(arrayimages);
        }*/

      } else {
        console.log(res.msg);
      }
    } else {
      console.log(res.msg);
    }
  };
  const items = [];
  for (var i = 0; i < abc.length; i++) {
    let arrayimages = {};
    arrayimages['id'] = abc[i].id
    arrayimages['name'] = abc[i].product_name

    items.push(arrayimages);
  }

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    //console.log("selected"+item.id)
    setselectid(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }
  useEffect(() => {

    getProductData();

  }, []);
  const registerurl = process.env.REACT_APP_BASE_URL + 'register';
  const loginurl = process.env.REACT_APP_BASE_URL + 'login';
  const listingurl = process.env.REACT_APP_BASE_URL + 'listing';
  const profileurl = process.env.REACT_APP_BASE_URL + 'my-profile';
  const equipment = process.env.REACT_APP_BASE_URL + 'equipment';
  const certification = process.env.REACT_APP_BASE_URL + 'certification';
  return (
    <>
      <header id="navbar_scroll_fixed">
        <div className="top_header">
          <Container>
            <Row className="align-items-center">
              <Col lg={4}>


                <Link className="logo" to="/">
                  <img src="../assets/img/logo.png" alt="" />
                </Link>
              </Col>
              <Col lg={8}>
                <form className="header_search">
                  {/*<input type="text" name="name1" className="form-control ml-auto" placeholder="Search by enquiry, request, name, keywords" id="place"/>
                  */}
                  <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus
                    fuseOptions={{ keys: ["name"] }}
                    className="form-control ml-auto"
                    id="place"
                    placeholder="Search by keywords"
                  />
                  {select_id && <a href={`/detail-product/${select_id}`} class="btn_search btn usearch"><i class="fas fa-search"></i></a>}
                </form>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="menu_header ">
          <Container className="p-0">
            <Navbar expand="lg">
              <NavbarBrand to="/" className="d-md-none d-inline-block"><img src="/assets/img/logo.png" alt="LaundryHUB" /></NavbarBrand>

              <NavbarToggler onClick={toggle}>
                <span class="navbar-toggler-icon"></span>
                <span class="navbar-toggler-icon"></span>
                <span class="navbar-toggler-icon"></span>
              </NavbarToggler>


              <Collapse id="basic-navbar-nav" className={`basic - navbar - nav ${isOpen}`} navbar>
                <Nav className="me-auto" navbar>
                  <li> <NavbarText><NavLink to="/" className="nav_link_hover">HOME</NavLink></NavbarText></li>
                  <li> <NavbarText><NavLink to="/about-us" className="nav_link_hover">ABOUT</NavLink></NavbarText></li>
                  <li> <NavbarText><a href="/listing" className="nav_link_hover">PRODUCTS</a></NavbarText></li>
                  {/*  <UncontrolledDropdown nav inNavbar>
             <DropdownToggle nav caret>
              PRODUCT
              </DropdownToggle>
              <DropdownMenu>
               
              
               
                <a href="/listing" className="nav_link_drp1">
                <DropdownItem>
                BUY PRODUCTS
                </DropdownItem>
                </a>
                <DropdownItem divider />
                <a href="/listing" className="nav_link_drp1">
                <DropdownItem>
                RENT PRODUCTS
                </DropdownItem>
              </a>
                
              </DropdownMenu>
           </UncontrolledDropdown>*/}

                  {/*<li> <NavbarText><a href="/equipment" className="nav_link_hover">EQUIPMENT</a></NavbarText></li>*/}
                  {/*<li> <NavbarText><a href="/certification" className="nav_link_hover">CERTIFICATION</a></NavbarText></li>*/}
                  <li><NavbarText><NavLink to="/contact-us" className="nav_link_hover">CONTACT</NavLink></NavbarText></li>


                </Nav>
                <div className="ml-auto login_menu">

                  {(() => {
                    if (checkAuthToken == null) {
                      return (
                        <ul>
                          <li> <NavbarText><a href="/login" className="nav_link_hover">LOGIN</a></NavbarText></li>
                          <li><NavbarText><a href="/register" className="nav_link_hover">REGISTER</a></NavbarText></li>
                        </ul>
                      )
                    }
                    else {
                      return (
                        <ul>
                          <li> <NavbarText><NavLink to="" onClick={Logout} className="nav_link_hover">LOGOUT</NavLink></NavbarText></li>
                          <li> <NavbarText><a href="/my-profile" className="nav_link_hover">PROFILE</a></NavbarText></li>
                        </ul>
                      )
                    }
                  })()}
                </div>


              </Collapse>
            </Navbar>

          </Container>

        </div>
      </header>
    </>
  );
};
export default Header;

