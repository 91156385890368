import React, { useState, useEffect } from 'react';
import Helper from '../../../constants/helper';
import apiUrl from '../../../constants/apiPath';
import { useAlert } from 'react-alert';
import useSession from 'react-session-hook';
import {Dropdown } from 'react-bootstrap';
import {Col, Container, Row,Form,FormGroup } from "reactstrap";
import { useHistory,Redirect } from "react-router-dom";
const ProfileHeader = (props) => {
  const session = useSession();
  const token = session.token;
  const alert = useAlert();  
  const [item, setItem] = useState({});
  const history = useHistory();
  const Logout = () => {
    
    localStorage.clear("usertoken");
    localStorage.clear("userid");
    alert.success("LOGOUT SUCCESSFULLY");
   history.push('/');
   };
  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  return (
    <div className="dashboard_header  bg-white">
        <section className="container-fluid">
            <Row className="align-items-center">
                <Col xs={6}>
                    <a href="/" className="dash_logo"><img src="../assets/img/logo.png" /> </a>
                </Col>
                <Col xs={6}>
                    <div className="DashHeader_right">
                          <div className="user">
                              <img src={item.image} />
                              
                          </div>
                         
              <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">{item.first_name}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/my-profile">My profile</Dropdown.Item>
                    {/*<Dropdown.Item href="#/action-2">Setting</Dropdown.Item>*/}
                    <Dropdown.Item href="#" onClick={Logout} className="green">Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                         
                      </div>
                </Col>
            </Row>
        </section>
      </div>


  );
}

export default ProfileHeader;
