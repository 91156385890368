import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import HomeSlider from "./Slider";
import ContactUs from "./ContactUs";
import Equipment from "./Equipment";
import { Settings, Settings1, Settings2, Settings3 } from "./BannerSettings";
import Helper from '../../constants/helper';
import parse from 'html-react-parser';
import apiUrl from '../../constants/apiPath';
import useSession from 'react-session-hook';
import { useLocation, useHistory } from "react-router-dom";



const Home = () => {

  const [staticDataBannertext, setstaticDataBannertext] = useState('')
  const getDataBannertext = async () => {
    try {
      let path = apiUrl.getStaticPage + 'banner-text';
      const fr = await Helper.get(path);
      const res = await fr.response.json();
      setstaticDataBannertext(res.result.content)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getDataBannertext()
  }, [])

  return (
    <>
      <section className="banner-section">
        <HomeSlider settings={Settings} number={11} />
      </section>
      <section className="buy_section">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} md={8} sm={8}>
              <div className="buy_content">
                <h1 className="mb-3"><span>Buy and Source</span> Equipment Online</h1>
                { /* <p>It is a long established fact that a reader will be distracted by the readable content of a
                  at its layout. The point of using Lorem English.</p> */ }
                {parse(staticDataBannertext)}
              </div>
            </Col>
            <Col lg={4} md={4} sm={4}>
              <div className="buy_link text-right">
                <a href={`/listing`} className="btn-change8">View Details</a>

              </div>
            </Col>
          </Row>
        </Container>

      </section>
      <section className="service_category">
        <Container>
          <div className="sec_heading ">
            <h2 className="text-center">
              Services by category
            </h2>
          </div>
          <Row>
            <Col md="11 m-auto pt-3 pt-md-5">
              <HomeSlider settings={Settings1} number={1} />
            </Col>
          </Row>
        </Container>

      </section>
      <section className="top_product_sec">
        <Container>
          <div className="inner_heading">
            <h2>
              Top Products
            </h2>
          </div>
          <Row>
            <Col lg="12" className="mt-4 pl-md-1 pr-md-0 p-0">
              <HomeSlider settings={Settings2} number={2} />
            </Col>
          </Row>
        </Container>

      </section>

      {/* top_products */}

      {/* mobile_slider */}

      <section className="top_product_sec mobile_product_sec d-block d-lg-none">
        <Container>
          <div className="inner_heading">
            <h2>Equipment for Rental / Sale</h2>
          </div>
          <Row>
            <Col lg="12" className="mt-4 pl-md-1 pr-md-0 p-0">
              <HomeSlider settings={Settings2} number={4} />
            </Col>
          </Row>
        </Container>
      </section>

      {/* mobile_slider */}




      <section className="equipment_sec top_product_sec d-none d-lg-block d-md-none">
        <Equipment />
      </section>

      <section className="partner_logo">
        <Container>
          <Row>
            <Col lg="12">
              <HomeSlider settings={Settings3} number={3} />
            </Col>
          </Row>
        </Container>

      </section>
      <ContactUs />
    </>
  );
}
export default Home;
